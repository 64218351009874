import { Link } from "react-router-dom";
import { ArrowLine, Download, Ethereum } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";

const Trade = props => {
  const {currencyOne, currencyTwo} = props;
  const { width } = useWindowSize();
  return (
    <div className="w-full h-full flex flex-col px-[22px] py-[14px] bg-custom-gray300 border-solid border-[1px] rounded-lg border-custom-gray400 shadow-homeCard dark:bg-custom-blue800 dark:border-custom-blue700">
      <div className="flex items-center lgx:flex-col lgx:items-start">
        <div className="flex items-center">
          <span>
            <Link to='/likiditeHavuzu'><ArrowLine size="20" className="fill-[#0000008A] rotate-180" /></Link>
          </span>
          <div className="flex mx-[12px] items-center">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="bg-custom-blue100 border-white border-solid border-[2px] rounded-full p-[2.7px] dark:bg-custom-blue500 ml-[-3px] dark:border-custom-blue800">
              <Ethereum size="15" />
            </span>
          </div>
          <span className="truncate text-xl text-custom-blue400 mr-[16px] lgx:text-base dark:text-white">
            {currencyOne} | {currencyTwo}
          </span>
          <span className="border-solid border-[1px] border-custom-blue100 border-opacity-[0.4] rounded px-[5px] text-sm text-black/50 dark:border-white dark:text-white">
            0.3%
          </span>
        </div>
        <div className="flex ml-auto lgx:flex-col lgx:w-full lgx:mt-[13px]">
          <div className="ml-auto flex items-center border-solid border-[1px] px-[12px] py-[8px] border-custom-blue100 border-opacity-[0.4] rounded lgx:mb-[8px] lgx:ml-0 dark:bg-custom-blue900 dark:border-custom-blue700">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="truncate pl-[9px] text-base text-custom-blue400 text-opacity-50 dark:text-white/60">
              1 {currencyOne} = {"<0.001"} {currencyTwo}
            </span>
          </div>
          <div className="ml-[12px] flex items-center border-solid border-[1px] px-[12px] py-[8px] border-custom-blue100 border-opacity-[0.4] rounded lgx:ml-0 dark:bg-custom-blue900 dark:border-custom-blue700">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="truncate pl-[9px] text-base text-custom-blue400 text-opacity-50 dark:text-white/60">
              1 {currencyOne} = 2.37k {currencyTwo}
            </span>
          </div>
        </div>
      </div>
      <div className="flex h-full w-full justify-end mt-[15px] lgx:flex-col">
        <div className="flex flex-col justify-between mr-[37px] w-[45%] lgx:w-full">
          <div className="grid grid-flow-col gap-[16px] items-start">
            <div>
              <span className="flex text-base text-custom-blue400 text-opacity-50 pb-[4px] before:h-[14px] before:w-[2px] before:bg-custom-blue400 before:bg-opacity-[0.54] before:my-auto before:mr-[8px] dark:text-white dark:before:bg-white">
                TVL
              </span>
              <span className="text-custom-blue100 text-xl pb-[6px] lgx:text-base dark:text-white/60">
                $280.29m
              </span>
              <span className="text-custom-blue300 text-sm flex items-center">
                <span className="mr-[4px]">
                  <ArrowLine
                    size="11"
                    className="fill-[#68B1F7] rotate-[-90deg]"
                  />
                </span>
                2.39%
              </span>
            </div>
            <div>
              <span className="truncate flex text-base text-custom-blue400 text-opacity-50 pb-[4px] before:h-[14px] before:w-[2px] before:bg-custom-blue400 before:bg-opacity-[0.54] before:my-auto before:mr-[8px] dark:text-white dark:before:bg-white">
                Volume 24h
              </span>
              <span className="text-custom-blue100 text-xl pb-[6px] lgx:text-base dark:text-white/60">
                $130.99m
              </span>
              <span className="text-custom-red200 text-sm flex items-center">
                <span className="mr-[4px]">
                  <ArrowLine
                    size="11"
                    className="fill-[#D64E50] rotate-[90deg]"
                  />
                </span>
                17.50%
              </span>
            </div>
            <div>
              <span className="flex text-base text-custom-blue400 text-opacity-50 pb-[4px] before:h-[14px] before:w-[2px] before:bg-custom-blue400 before:bg-opacity-[0.54] before:my-auto before:mr-[8px] dark:text-white dark:before:bg-white">
                24h fees
              </span>
              <span className="text-custom-blue100 text-xl pb-[6px] lgx:text-base dark:text-white/60">
                $392.96m
              </span>
            </div>
          </div>
          {width > 1024 && (
            <button className="py-[10px] text-xl w-full border-none rounded-[8px] bg-custom-orange text-custom-gray600 flex items-center justify-center">
              Trade
            </button>
          )}
        </div>
        <div className="flex flex-col justify-between w-[45%] lgx:w-full lgx:mt-[13px]">
          <div className="flex flex-col border-solid border-[1px] px-[12px] py-[9px] border-custom-blue100 border-opacity-[0.4] rounded dark:border-custom-blue700 dark:bg-custom-blue900">
            <h1 className="text-base text-custom-blue400 text-opacity-50 mb-[10px] dark:text-white/60">
              Total Tokens Locked
            </h1>
            <div className="flex items-center py-[12px]">
              <span className="bg-custom-blue100 rounded-full p-[2.7px] dark:bg-custom-blue500 mr-[12px]">
                <Ethereum size="15" />
              </span>
              <span className="text-custom-blue400 text-base dark:text-white/60">
                {currencyOne}
              </span>
              <span className="text-custom-blue400 text-base ml-auto dark:text-white/60">
                142.34m
              </span>
            </div>
            <div className="flex items-center py-[12px]">
              <span className="bg-custom-blue100 rounded-full p-[2.7px] dark:bg-custom-blue500 mr-[12px]">
                <Ethereum size="15" />
              </span>
              <span className="text-custom-blue400 text-base dark:text-white/60">
                {currencyTwo}
              </span>
              <span className="text-custom-blue400 text-base ml-auto dark:text-white/60">
                58.14k
              </span>
            </div>
          </div>
          {width <= 1024 && (
            <button className="py-[10px] text-xl w-full border-none rounded-[8px] bg-custom-orange text-custom-gray600 flex items-center justify-center lgx:my-[11px] lgx:text-base">
              Trade
            </button>
          )}
          <button className="py-[10px] text-xl w-full border-solid border-[1px] border-custom-gray400 rounded-[8px] bg-custom-gray1100 text-custom-blue400 text-opacity-50 flex items-center justify-center lgx:text-base dark:bg-custom-blue400 dark:bg-opacity-[0.54] dark:text-white/60 dark:border-custom-blue700">
            <Download
              size="18"
              className="fill-[#002C3E8A] mr-[4px] dark:fill-[#FFFFFF99]"
            />
            Add Liquidity
          </button>
        </div>
      </div>
    </div>
  );
};

export default Trade;
