import * as React from "react";

const Arrow = ({ size, fill, fillOpacity, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.30032 4.94653C5.42818 4.82808 5.5 4.66745 5.5 4.49991C5.5 4.33238 5.42814 4.17174 5.30027 4.05334L1.66391 0.68499C1.39764 0.438336 0.965954 0.438336 0.699681 0.68499C0.433454 0.931602 0.433409 1.33152 0.699727 1.57817L3.85395 4.49996L0.699682 7.42186C0.433455 7.66847 0.433409 8.06839 0.699727 8.31505C0.966 8.56166 1.39768 8.56166 1.66395 8.315L5.30032 4.94653Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Arrow;
