import { ArrowLine } from "src/commons/Icons";
import PropTypes from "prop-types";
import { useState } from "react";

const Pagination = ({ id, name, onChange, label, className }) => {
  const [pageNum, setPageNum] = useState(1);
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <span onClick={() => setPageNum(pageNum !== 1 ? pageNum - 1 : 1)}>
        <ArrowLine fill="#68B1F7" size="20" className="rotate-180" />
      </span>
      <span className="text-base text-custom-blue400 text-opacity-[0.54] mx-[13px] lgx:text-[10px] lgx:leading-none dark:text-white/60">
        Page {pageNum} of 5
      </span>
      <span onClick={() => setPageNum(pageNum !== 5 ? pageNum + 1 : 5)}>
        <ArrowLine fill="#68B1F7" size="20" />
      </span>
    </div>
  );
};

Pagination.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Pagination;
