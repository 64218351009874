import * as React from "react";

const Remove = ({ size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clip-path="url(#clip0_49_9995)">
      <path
        d="M12.0069 2.04943C9.27318 -0.680214 4.8236 -0.680214 2.08984 2.04943C0.765594 3.3722 0.0361328 5.1305 0.0361328 7.0005C0.0361328 8.8705 0.765594 10.6288 2.08984 11.9511C3.45699 13.3161 5.2527 13.9984 7.04838 13.9984C8.84405 13.9984 10.6398 13.3161 12.0069 11.9511C14.7407 9.22141 14.7407 4.77959 12.0069 2.04943ZM11.2389 11.1842C8.92826 13.4913 5.16849 13.4913 2.85786 11.1842C1.73895 10.0669 1.12245 8.5809 1.12245 7.0005C1.12245 5.4201 1.73895 3.93406 2.85786 2.81629C5.16849 0.509142 8.92826 0.509688 11.2389 2.81629C13.549 5.12344 13.549 8.87756 11.2389 11.1842Z"
        fill="#D64E50"
      />
      <path
        d="M9.38915 8.4992L7.85144 6.96599L9.38915 5.43279C9.60098 5.22129 9.60098 4.87798 9.3897 4.6659C9.17733 4.45331 8.83351 4.45385 8.62113 4.66536L7.08236 6.19965L5.54358 4.66536C5.33121 4.45385 4.98738 4.45331 4.77501 4.6659C4.56319 4.87796 4.56319 5.22126 4.77556 5.43279L6.31327 6.96599L4.77556 8.4992C4.56319 8.7107 4.56319 9.054 4.77501 9.26608C4.88094 9.37237 5.02052 9.42498 5.15958 9.42498C5.29865 9.42498 5.43768 9.37182 5.54361 9.2666L7.08238 7.73231L8.62116 9.2666C8.72709 9.37237 8.86612 9.42498 9.00519 9.42498C9.14425 9.42498 9.28383 9.37182 9.38976 9.26608C9.60153 9.054 9.60153 8.7107 9.38915 8.4992Z"
        fill="#D64E50"
      />
    </g>
    <defs>
      <clipPath id="clip0_49_9995">
        <rect
          width="14.0211"
          height="14"
          fill="white"
          transform="translate(0.0361328)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Remove;
