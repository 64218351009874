import * as React from "react";

const Trezor = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.51046 1C4.91935 1 2.82241 3.10936 2.82241 5.71581V7.48325C1.91251 7.64895 1 7.86988 1 8.15656V17.3804C1 17.3804 1 17.6355 1.285 17.7565C2.31778 18.1773 6.38093 19.6239 7.31436 19.9553C7.43463 20 7.46862 20 7.5 20C7.54445 20 7.56537 20 7.68564 19.9553C8.61907 19.6239 12.6927 18.1773 13.7255 17.7565C13.9895 17.646 14 17.3909 14 17.3909V8.15656C14 7.86988 13.1006 7.63843 12.1881 7.48325V5.71581C12.2011 3.10936 10.0911 1 7.51046 1ZM7.51046 3.25401C9.03741 3.25401 9.96038 4.18245 9.96038 5.71844V7.25443C8.24779 7.13344 6.7862 7.13344 5.06315 7.25443V5.71844C5.06315 4.17982 5.98612 3.25401 7.51046 3.25401ZM7.5 9.51633C9.63093 9.51633 11.4193 9.68203 11.4193 9.97924V15.7339C11.4193 15.8234 11.4089 15.8339 11.3305 15.8654C11.2546 15.8996 7.6961 17.191 7.6961 17.191C7.6961 17.191 7.55229 17.2357 7.51046 17.2357C7.46601 17.2357 7.32482 17.1805 7.32482 17.1805C7.32482 17.1805 3.76629 15.8891 3.69047 15.8549C3.61464 15.8207 3.60157 15.8102 3.60157 15.7234V9.96872C3.58065 9.67151 5.36907 9.51633 7.5 9.51633Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="white"
    />
  </svg>
);

export default Trezor;
