import { useState, useRef, useEffect } from "react";
import { Arrow, Ava, Bitcoin, Ethereum, Search, Weth } from "src/commons/Icons";
import useOnClickOutside from "src/utils/useOnClickOutside";
const SwapDropdown = ({ width, tokens, setAddress, seletectedAddress }) => {
  const widthTemp = () => {
    return "w-[" + width + "]";
  };
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));
  return (
    <div ref={ref} className="cursor-pointer">
      <div
        className={`flex items-center ${width && widthTemp()}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[8px] dark:bg-custom-blue500">
          {seletectedAddress !== ""  && seletectedAddress?.logoURI? (
            <img
              src={seletectedAddress.logoURI}
              style={{ width: 15, height: 15 }}
            />
          ) : (
            <Ethereum size="15" />
          )}
        </span>
        <span className="text-black text-opacity-[0.54] mr-[11px] dark:text-white">
          {seletectedAddress !== "" && seletectedAddress?.symbol ? seletectedAddress.symbol : "ETH"}
        </span>
        <span className="text-black text-opacity-[0.54] dark:text-white">
          <Arrow
            size="9"
            className={`fill-[rgba(0,0,0,0.54)] dark:fill-[white] ${
              isOpen ? "-rotate-90" : " rotate-90"
            }`}
          />
        </span>
      </div>
      {isOpen ? (
        <div className="z-[12] absolute w-full h-[318px] py-[22px] px-[16px] bg-custom-gray700 left-0 top-[46px] shadow-swapDropdown dark:bg-custom-blue1100">
          <div className="grid grid-flow-col gap-[14px] mb-[8px] overflow-x-auto overflow-y-hidden scrollhide">
            <div className="flex bg-white items-center justify-center rounded-[6px] px-[14px] py-[8px] dark:bg-custom-blue900">
              <span  onClick={()=>setAddress(tokens['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'])} className="bg-custom-blue100 rounded-full p-[2.5px] mr-[8px] dark:bg-custom-blue500">
                <Ethereum size="15" />
              </span>
              <span className="text-black text-opacity-[0.38] mr-[11px] dark:text-white">
                ETH
              </span>
            </div>
            {/* <div className="flex bg-white items-center justify-center rounded-[6px] px-[14px] py-[8px] dark:bg-custom-blue900">
                <Bitcoin size={16} fill="#002C3E" fillOpacity="0.54" />

              <span className="text-black text-opacity-[0.38] mr-[11px] dark:text-white">
                USDT
              </span>
            </div>
            <div className="flex bg-white items-center justify-center rounded-[6px] px-[14px] py-[8px] dark:bg-custom-blue900">
              <Ava size={22} fill="#002C3E" fillOpacity="0.54" />
              <span className="text-black text-opacity-[0.38] mr-[11px] dark:text-white">
                Ava
              </span>
            </div> */}
          </div>
          {/* <label className="w-full px-[12px] py-[8px] flex items-center bg-white rounded-[6px] mb-[20px] dark:bg-custom-blue900">
            <Search
              size="16"
              className="mr-[11px] fill-[rgba(0,0,0,0.38)] dark:fill-[white]"
            />
            <input
              type="text"
              placeHolder="Deneyin “DTL”"
              className="w-full outline-none bg-transparent placeholder-custom-gray800 placeholder-opacity-[0.6] dark:text-white dark:placeholder-white/60"
            />
          </label> */}
          <div className="overflow-y-auto overflow-x-hidden scrollhide grid grid-flow-row gap-[16px] h-[230px]">
            {Object.keys(tokens).map((key, index) => (
              <div
                onClick={() => {
                  setIsOpen(false);
                  setAddress(tokens[key]);
                }}
                className="flex items-center"
                key={index}
              >
                <img
                  src={tokens[key].logoURI}
                  style={{ width: 32, height: 32 }}
                />
                <span className="text-black text-opacity-[0.38] text-lg dark:text-white/70">
                  {tokens[key].name}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SwapDropdown;
