import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  const handlePath = (pathname) => {
    if (pathname === location.pathname) {
      return "after:w-full after:h-[2px] text-[20px] font-semibold text-custom-blue100 text-opacity-100 dark:text-custom-blue500";
    } else {
      return "after:w-[0px] text-opacity-[0.7] text-custom-blue400 font-normal text-[18px] hover:text-custom-blue100 hover:text-opacity-100 hover:after:w-full hover:after:transition-custom dark:hover:text-custom-blue500 dark:text-white dark:text-opacity-70";
    }
  };
  return (
    <ul className="grid gap-[36px] grid-flow-col ml-auto  lgx:ml-[unset] lgx:justify-center lgx:gap-[17px]">
      <li
        className={`relative truncate px-[10px] transition-custom2 lgx:px-[unset] lgx:text-[12px] dark:after:bg-custom-blue500 after:bottom-0 after:left-[50%] after:translate-x-[-50%] hover:after:h-[2px] after:bg-custom-blue100 after:rounded-full after:absolute ${handlePath(
          "/"
        )}`}
      >
        <Link to="/">Takas</Link>
      </li>
      <li
        className={`relative truncate px-[10px] transition-custom2 lgx:px-[unset] lgx:text-[12px] dark:after:bg-custom-blue500 after:bottom-0 after:left-[50%] after:translate-x-[-50%] hover:after:h-[2px] after:bg-custom-blue100 after:rounded-full after:absolute ${handlePath(
          "/limitliEmir"
        )}`}
      >
        <Link to="/limitliEmir">Limitli Emir</Link>
      </li>
      <li
        className={`relative truncate px-[10px] transition-custom2 lgx:px-[unset] lgx:text-[12px] dark:after:bg-custom-blue500 after:bottom-0 after:left-[50%] after:translate-x-[-50%] hover:after:h-[2px] after:bg-custom-blue100 after:rounded-full after:absolute ${handlePath(
          "/likiditeHavuzu"
        )}`}
      >
        <Link to="/likiditeHavuzu">Likidite Havuzu</Link>
      </li>
      <li
        className={`relative truncate px-[10px] transition-custom2 lgx:px-[unset] lgx:text-[12px] dark:after:bg-custom-blue500 after:bottom-0 after:left-[50%] after:translate-x-[-50%] hover:after:h-[2px] after:bg-custom-blue100 after:rounded-full after:absolute ${handlePath(
          "/transfer"
        )}`}
      >
        <Link to="/transfer">Transfer</Link>
      </li>
      {/* <li
        className={`relative truncate px-[10px] transition-custom2 lgx:px-[unset] lgx:text-[12px] dark:after:bg-custom-blue500 after:bottom-0 after:left-[50%] after:translate-x-[-50%] hover:after:h-[2px] after:bg-custom-blue100 after:rounded-full after:absolute ${handlePath(
          "/odeme"
        )}`}
      >
        <Link to="/odeme">Ödeme</Link>
      </li> */}
    </ul>
  );
};

export default Navigation;
