import { ArrowLine, Arrow, Plus } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";

const UpperPools = () => {
  const { width } = useWindowSize();

  return (
    <div className="w-full h-full xlx:flex xlx:items-end lgx:flex-col">
      {(width > 1280 || width < 1024) && (
        <div className="h-[36px] mb-[8px] text-xl flex justify-between lgx:w-full">
          <div className="h-full w-full mr-[9px] border-solid border-[1px] border-custom-blue400 border-opacity-[0.16] rounded-[8px] flex items-center justify-center dark:bg-custom-blue900">
            <Arrow
              size="12"
              className=" rotate-90 fill-[#002C3E8A] mr-[9px] dark:fill-[#FFFFFF99]"
            />
            <span className=" text-base text-custom-blue400 text-opacity-[0.54] dark:text-white dark:text-opacity-[0.6]">
              Daha Fazla
            </span>
          </div>
          <button className="h-full text-base w-full border-none rounded-[8px] bg-custom-orange text-custom-gray600 flex items-center justify-center dark:text-custom-gray600">
            <Plus size="12" className="fill-[#F5F5F5] mr-[9px]" />
            Yeni Pozisyon
          </button>
        </div>
      )}
      <div className="relative h-minus44 w-full flex flex-col items-center justify-center bg-custom-blue400 bg-opacity-[0.08] rounded-[8px] shadow-homeCard dark:bg-custom-blue800 dark:border-custom-blue700">
        <h1 className="mb-[3px] text-xl text-custom-blue100 font-semibold lgx:text-sm dark:text-white">
          Üst Havuzlar
        </h1>
        <p className="text-base text-custom-blue100 px-[20px] text-center lgx:text-xs dark:text-white dark:text-opacity-60">
          Deximec Analitiğindeki Popüler Havuzları Keşfedin
        </p>
        <ArrowLine
          size="20"
          className="absolute bottom-[16px] right-[25px] fill-[#284862] dark:fill-[#FFFFFF]"
        />
      </div>
    </div>
  );
};

export default UpperPools;
