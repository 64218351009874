import { useWeb3React } from "@web3-react/core";
import { Close, Ledger, Metamask, Trezor } from "src/commons/Icons";
import { connectorsByName } from "src/hooks/connectorsParams";
import { resetWalletConnector } from "src/hooks/hooks";

const ImportWallet = ({ setActivatingConnector, onClose }) => {
  const context = useWeb3React();
  const { connector, library, account, activate, deactivate, active, chainId } =
  context;
  return (
    <div className="px-[24px] py-[21px] relative flex flex-col items-center dark:bg-custom-blue900 w-full h-full rounded-[8px]">
      <span onClick={onClose}>
        <Close
          size="16"
          className="fill-[#0000008A] absolute top-[21px] right-[24px] dark:fill-[#FFFFFF99]"
        />
      </span>
      <h1 className="text-custom-gray1200 font-semibold text-xl mb-[16px] self-start dark:text-white/60">
        Cüzdanını İçe Aktar
      </h1>
      <div className="grid grid-cols-2 gap-[16px] mb-[17px] w-full">
        <div className="hidden md:flex flex items-center justify-center bg-custom-blue400 bg-opacity-[0.13] rounded-[8px] py-[13px] cursor-pointer"
        onClick={() => {
          setActivatingConnector(connectorsByName["Injected"]);
          activate(connectorsByName["Injected"]);
          localStorage.setItem("walletType", "Injected");
        }}
        
        >
          {/* <span className="mr-[8px]">
            <Metamask
              size="30"
              className="fill-[#002C3E8A] dark:fill-[#FFFFFF]"
            />
          </span> */}
          <span className="text-sm text-custom-blue400 text-opacity-50 dark:text-white">
            METAMASK
          </span>
        </div>
        <div className="flex items-center justify-center bg-custom-blue400 bg-opacity-[0.13] rounded-[8px] py-[13px] cursor-pointer"
        
        onClick={() => {
          setActivatingConnector(connectorsByName["WalletConnect"]);
          activate(
            connectorsByName["WalletConnect"],
            undefined,
            true
          ).catch((error) => {
            setActivatingConnector(undefined);
            resetWalletConnector(connectorsByName["WalletConnect"]);
          });
        }}
        
        >
          {/* <span className="mr-[8px]"> */}
          {/* </span> */}
          <span className="text-sm text-custom-blue400 text-opacity-50 dark:text-white">
            Wallet Connect
          </span>
        </div>
        <div className="flex items-center justify-center bg-custom-blue400 bg-opacity-[0.13] rounded-[8px] py-[13px]">
          {/* <span className="mr-[8px]">
            <Metamask
              size="30"
              className="fill-[#002C3E8A] dark:fill-[#FFFFFF]"
            />
          </span> */}
          <span className="text-sm text-custom-blue400 text-opacity-50 dark:text-white">
            COINBASE
          </span>
        </div>
        <div className="flex items-center justify-center bg-custom-blue400 bg-opacity-[0.13] rounded-[8px] py-[13px]">
          {/* <span className="mr-[8px]">
            <Trezor
              size="30"
              className="fill-[#002C3E8A] dark:fill-[#FFFFFF]"
            />
          </span> */}
          <span className="text-sm text-custom-blue400 text-opacity-50 dark:text-white">
            TREZOR
          </span>
        </div>
      </div>
      <p className="text-black/50 visible hidden sm:block text-sm w-[90%] text-center dark:text-white/60">
        Deximec'i Kullanarak{" "}
        <span className="text-custom-blue500">Şartlar Ve Koşulları</span> Kabul
        Etmiş Olursunuz
      </p>
    </div>
  );
};

export default ImportWallet;
