import { getAddress } from "@ethersproject/address";
import { Contract } from "@ethersproject/contracts";
import { AddressZero } from "@ethersproject/constants";
import { isEmpty } from "src/utils/method";

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is optional
export function getContract(address, ABI, library, account) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

const CONSERVATIVE_BLOCK_GAS_LIMIT = 10_000_000; // conservative, hard-coded estimate of the current block gas limit
export const DEFAULT_GAS_REQUIRED = 200_000; // the default value for calls that don't specify gasRequired

// chunks array into chunks
// evenly distributes items among the chunks
export default function chunkArray(
  items,
  gasLimit = CONSERVATIVE_BLOCK_GAS_LIMIT * 10
) {
  const chunks = [];
  let currentChunk = [];
  let currentChunkCumulativeGas = 0;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    // calculate the gas required by the current item
    const gasRequired = item?.gasRequired ?? DEFAULT_GAS_REQUIRED;

    // if the current chunk is empty, or the current item wouldn't push it over the gas limit,
    // append the current item and increment the cumulative gas
    if (
      currentChunk.length === 0 ||
      currentChunkCumulativeGas + gasRequired < gasLimit
    ) {
      currentChunk.push(item);
      currentChunkCumulativeGas += gasRequired;
    } else {
      // otherwise, push the current chunk and create a new chunk
      chunks.push(currentChunk);
      currentChunk = [item];
      currentChunkCumulativeGas = gasRequired;
    }
  }
  if (currentChunk.length > 0) chunks.push(currentChunk);

  return chunks;
}

export const readJson = async (link) => {
  try {
    const response = await fetch(link);
    return response.json();
  } catch (error) {
    console.log("readJson: error: ", error);
  }
};

export const fixCurrencies = (token) => {
  if (
    token.address === "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984" &&
    token.symbol === "UNI"
  ) {
    return "https://cryptologos.cc/logos/uniswap-uni-logo.svg";
  }
  if (isEmpty(token.logoURI))
    return "https://crypto-comparator.com/images/crypto/symbol/coin-not-found.svg";
  return token.logoURI;
};

export const renderTokens = (balances, height) => {
  let CURRENCIES = [];
  CURRENCIES.push(
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexWrap: "wrap",
        height: height ? height : 165,
        maxHeight: 400,
        overflowY: "scroll",
      }}
    >
      {balances.map((res) => {
        return (
          <div
            style={{
              width: "30%",
              maxWidth: "30%",
              margin: 0,
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "0.5rem",
              flexDirection: "column",
            }}
          >
            <img
              src={fixCurrencies(res)}
              alt="Picture of the author"
              width={30}
              height={30}
            />
            <span
              style={{
                height: "100%",
                maxWidth: "70%",
                overflowX: "scroll",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {res.balance.toFixed(2)}
            </span>
          </div>
        );
      })}
    </div>
  );
  return CURRENCIES;
};
