import * as React from "react";

const Message = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.9136 18.9452L22.5197 14.824C23.1916 13.4286 23.5467 11.8767 23.549 10.3152C23.5532 7.6002 22.5192 5.03309 20.6375 3.08681C18.7555 1.14015 16.2476 0.0443341 13.5757 0.00133215C10.8052 -0.0431461 8.20098 1.02747 6.24315 3.01642C4.35531 4.93427 3.30821 7.46262 3.27507 10.1645C1.41441 11.5877 0.316178 13.8066 0.319788 16.1766C0.321522 17.2857 0.567241 18.3882 1.03285 19.3852L0.0727092 22.2238C-0.0923379 22.7118 0.0282248 23.2416 0.387381 23.6065C0.640132 23.8633 0.973366 24 1.31607 24C1.46026 24 1.60613 23.9758 1.7484 23.9261L4.54257 22.9507C5.5239 23.4237 6.60911 23.6734 7.70083 23.6751C7.70476 23.6751 7.70851 23.6751 7.71245 23.6751C10.0801 23.675 12.2809 22.5306 13.6767 20.5939C15.1292 20.5551 16.5664 20.1979 17.8656 19.5522L21.9223 20.9683C22.0914 21.0273 22.2647 21.0561 22.436 21.0561C22.8433 21.0561 23.2393 20.8936 23.5397 20.5884C23.9665 20.1548 24.1097 19.5252 23.9136 18.9452ZM7.71236 22.2238C7.70931 22.2238 7.70608 22.2238 7.70303 22.2238C6.73675 22.2223 5.77717 21.9803 4.92821 21.524C4.75365 21.4303 4.54871 21.414 4.36206 21.4792L1.49691 22.4794L2.48143 19.5687C2.54556 19.379 2.52962 19.1708 2.43727 18.9935C1.98812 18.131 1.7499 17.1562 1.7484 16.1745C1.74601 14.5954 2.35065 13.0987 3.40801 11.978C3.75339 14.1169 4.75684 16.088 6.31225 17.64C7.85617 19.1806 9.79689 20.1615 11.8909 20.4822C10.7852 21.5894 9.29477 22.2238 7.71236 22.2238ZM22.5295 19.5621C22.4888 19.6034 22.4406 19.6147 22.3865 19.5957L18.0436 18.0796C17.9681 18.0533 17.8897 18.0403 17.8115 18.0403C17.6962 18.0403 17.5814 18.0686 17.4775 18.1245C16.2375 18.7909 14.8363 19.1444 13.4253 19.1465C13.4206 19.1465 13.4164 19.1465 13.4118 19.1465C8.6838 19.1465 4.7785 15.2447 4.70359 10.4427C4.66585 8.02422 5.57139 5.75131 7.25331 4.04261C8.93523 2.33391 11.1729 1.4143 13.5531 1.4524C18.2845 1.52864 22.1278 5.50344 22.1205 10.3129C22.1183 11.7464 21.7704 13.17 21.1145 14.4296C21.0222 14.6069 21.0062 14.8151 21.0703 15.0048L22.5626 19.4168C22.5812 19.472 22.5701 19.5209 22.5295 19.5621Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M17.6663 6.45264H9.15569C8.76119 6.45264 8.44141 6.77756 8.44141 7.17829C8.44141 7.57907 8.76124 7.90394 9.15569 7.90394H17.6663C18.0608 7.90394 18.3806 7.57902 18.3806 7.17829C18.3806 6.77756 18.0608 6.45264 17.6663 6.45264Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M17.6663 9.43726H9.15569C8.76119 9.43726 8.44141 9.76218 8.44141 10.1629C8.44141 10.5636 8.76124 10.8886 9.15569 10.8886H17.6663C18.0608 10.8886 18.3806 10.5636 18.3806 10.1629C18.3806 9.76218 18.0608 9.43726 17.6663 9.43726Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M14.3904 12.4216H9.15569C8.76119 12.4216 8.44141 12.7466 8.44141 13.1473C8.44141 13.5481 8.76123 13.8729 9.15569 13.8729H14.3903C14.7848 13.8729 15.1046 13.548 15.1046 13.1473C15.1046 12.7466 14.7849 12.4216 14.3904 12.4216Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Message;
