import * as React from "react";

const ArrowDouble = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.6804 5.29294C23.8935 5.48814 24 5.74407 24 6C24 6.25593 23.8935 6.51186 23.6804 6.70713L18.2259 11.7071C17.7999 12.0976 17.1092 12.0976 16.6831 11.7071C16.2572 11.3166 16.2572 10.6834 16.6831 10.2929L20.2754 6.99999L1.09091 6.99999C0.488437 6.99999 2.86409e-07 6.55226 2.62268e-07 6C2.38128e-07 5.44774 0.488437 5.00001 1.09091 5.00001L20.2754 5L16.6831 1.70709C16.2572 1.31656 16.2572 0.683429 16.6831 0.292898C17.1091 -0.0976335 17.7999 -0.0976336 18.2259 0.292898L23.6804 5.29294Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M0.319565 19.2929C0.106547 19.4881 2.51081e-07 19.7441 2.62268e-07 20C2.73455e-07 20.2559 0.106547 20.5119 0.319565 20.7071L5.77411 25.7071C6.20007 26.0976 6.89084 26.0976 7.31687 25.7071C7.74284 25.3166 7.74284 24.6834 7.31687 24.2929L3.72458 21L22.9091 21C23.5116 21 24 20.5523 24 20C24 19.4477 23.5116 19 22.9091 19L3.72458 19L7.31687 15.7071C7.74284 15.3166 7.74284 14.6834 7.31687 14.2929C6.89091 13.9024 6.20015 13.9024 5.77411 14.2929L0.319565 19.2929Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default ArrowDouble;
