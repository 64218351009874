import * as React from "react";

const Bitcoin = ({ size, fill, fillOpacity }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0962 4.92452C10.9217 3.17725 9.35541 2.59169 7.37617 2.42469V0H5.8446V2.35997C5.44247 2.35997 5.03058 2.36728 4.62194 2.37563V0H3.09037L3.08928 2.4226C2.75761 2.42886 2.43135 2.43512 2.11376 2.43512V2.42782L0.00120638 2.42677V4.00287C0.00120638 4.00287 1.13281 3.98199 1.1133 4.00183C1.73439 4.00183 1.936 4.34836 1.99453 4.64792V7.40975C2.03788 7.40975 2.09316 7.41183 2.15603 7.42018H1.99453L1.99344 11.2895C1.96635 11.4773 1.85145 11.7769 1.41788 11.7779C1.4374 11.7946 0.304703 11.7779 0.304703 11.7779L0.00012207 13.5398H1.99453C2.36523 13.5398 2.73051 13.5461 3.0882 13.5482L3.08928 16H4.61977V13.5743C5.03925 13.5826 5.44572 13.5858 5.84352 13.5858L5.84243 16H7.37401V13.5534C9.94939 13.4114 11.7541 12.7862 11.9774 10.4565C12.1584 8.58086 11.2425 7.74271 9.7803 7.40453C10.6702 6.97032 11.2262 6.20314 11.0962 4.92452ZM8.95219 10.1664C8.95219 11.9982 5.69502 11.7905 4.65554 11.7905V8.5412C5.69502 8.54328 8.95219 8.25625 8.95219 10.1664ZM8.23897 5.58314C8.23897 7.25005 5.52051 7.05486 4.65554 7.05591V4.11038C5.52159 4.11038 8.24006 3.84526 8.23897 5.58314Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M1.99524 7.34521H2.22286V7.507H1.99524V7.34521Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Bitcoin;
