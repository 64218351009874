import * as React from "react";

const Tick = ({ size, fill, fillOpacity, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 0C3.13111 0 0 3.13078 0 7C0 10.8689 3.13078 14 7 14C10.8689 14 14 10.8692 14 7C14 3.13111 10.8692 0 7 0ZM10.7055 5.08772L6.22095 9.57223C6.03879 9.75439 5.7432 9.75445 5.56098 9.57223L3.29454 7.30579C3.11229 7.12354 3.11229 6.82806 3.29454 6.64582C3.47676 6.46357 3.77226 6.46357 3.95451 6.64582L5.89099 8.58227L10.0455 4.42772C10.2278 4.24547 10.5233 4.24547 10.7055 4.42772C10.8877 4.61002 10.8877 4.90547 10.7055 5.08772Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Tick;
