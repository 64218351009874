import { Tick } from "src/commons/Icons";
import PropTypes from "prop-types";

const Radio = ({ id, name, onChange, label, className }) => {
  return (
    <label className={`relative flex items-center cursor-pointer ${className}`}>
      <input
        type="radio"
        name={name}
        id={id}
        className="hidden peer"
        onChange={(e) => onChange(e)}
      />
      <div className="p-[6px] border-[1px] border-solid border-custom-gray100 rounded-full"></div>
      <div className="absolute hidden peer-checked:block">
        <Tick size="14" fill="#F5F5F5" />
      </div>
      <span className="pl-[6px] text-white text-[15px]">{label}</span>
    </label>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Radio;
