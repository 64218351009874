import TransactionSummaryRow from "src/components/ui/row/TransactionSummaryRow";

const TransactionSummary = () => {
  return (
    <div className="bg-custom-gray300 rounded-[8px] dark:bg-custom-blue800">
      <div className="grid grid-flow-col gap-[28px] overflow-x-auto overflow-y-hidden scrollhide px-[17px] pt-[12px] pb-[9px] w-min xlx:pt-[11px] xlx:pb-[5.57px] xlx:px-[10px] lgx:w-full lgx:py-[9px] lgx:px-[11px]">
        <label className="relative cursor-pointer w-max flex items-center justify-center mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="transactionType"
            id="last"
            className="hidden peer"
            defaultChecked
          />
          <span className="relative w-full text-lg text-black text-opacity-[0.54] peer-checked:text-custom-blue100 peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:text-[10px] peer-checked:after:absolute peer-checked:after:h-[2px] peer-checked:after:bg-custom-blue100 peer-checked:after:rounded-full peer-checked:after:bottom-0 peer-checked:after:w-full peer-checked:after:left-[50%] peer-checked:after:translate-x-[-50%] peer-checked:after:transition-custom dark:text-white/70 dark:peer-checked:text-custom-blue500 dark:peer-checked:after:bg-custom-blue500">
            Son İşlemler
          </span>
        </label>
        <label className="relative cursor-pointer w-max flex items-center justify-center mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="transactionType"
            id="open"
            className="hidden peer"
          />
          <span className="relative w-full text-lg text-black text-opacity-[0.54] peer-checked:text-custom-blue100 peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:text-[10px] peer-checked:after:absolute peer-checked:after:h-[2px] peer-checked:after:bg-custom-blue100 peer-checked:after:rounded-full peer-checked:after:bottom-0 peer-checked:after:w-full peer-checked:after:left-[50%] peer-checked:after:translate-x-[-50%] peer-checked:after:transition-custom dark:text-white/70 dark:peer-checked:text-custom-blue500 dark:peer-checked:after:bg-custom-blue500">
            Açık Emirler
          </span>
        </label>
        <label className="relative cursor-pointer w-max flex items-center justify-center mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="transactionType"
            id="history"
            className="hidden peer"
          />
          <span className="relative w-full text-lg text-black text-opacity-[0.54] peer-checked:text-custom-blue100 peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:text-[10px] peer-checked:after:absolute peer-checked:after:h-[2px] peer-checked:after:bg-custom-blue100 peer-checked:after:rounded-full peer-checked:after:bottom-0 peer-checked:after:w-full peer-checked:after:left-[50%] peer-checked:after:translate-x-[-50%] peer-checked:after:transition-custom dark:text-white/70 dark:peer-checked:text-custom-blue500 dark:peer-checked:after:bg-custom-blue500">
            Emir Geçmişi
          </span>
        </label>
      </div>
      <div className="overflow-x-auto overflow-y-auto scrollhide w-full">
        <div className="grid grid-flow-col auto-cols-[minmax(120px,160px)] py-[6px] px-[31px] justify-items-start lgx:py-[3px]">
          <span className="font-semibold text-xs text-custom-blue100 truncate w-full lgx:text-[10px] dark:text-white">
            Tanım
          </span>
          <span className="font-semibold text-xs text-custom-blue100 truncate w-full lgx:text-[10px] dark:text-white">
            Tarih
          </span>
          <span className="font-semibold text-xs text-custom-blue100 truncate w-full lgx:text-[10px] dark:text-white">
            Cüzdan Adresi
          </span>
          <span className="font-semibold text-xs text-custom-blue100  lgx:text-[10px] dark:text-white">
            İşlem Tipi
          </span>
          <span className="font-semibold text-xs text-custom-blue100 truncate w-full lgx:text-[10px] dark:text-white">
            Token
          </span>
          <span className="font-semibold text-xs text-custom-blue100 truncate w-full lgx:text-[10px] dark:text-white">
            Durum
          </span>
          <span className="font-semibold text-xs text-custom-blue100 truncate w-full lgx:text-[10px] dark:text-white">
            Miktar
          </span>
        </div>
        <div className="h-[104px] xlx:h-[119px] lgx:h-[78px]">
          {Array.from({ length: 16 }).map((item, index) => (
            <TransactionSummaryRow key={index} _key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionSummary;
