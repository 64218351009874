import { Ethereum } from "src/commons/Icons";
import PercentageSelector from "src/components/ui/input/PercentageSelector";
const SwapLimited = () => {
  return (
    <div className="w-full h-full border-solid border-[1px] border-custom-gray400 rounded-[8px] flex justify-between bg-custom-gray300 px-[42px] py-[17px] dark:bg-custom-blue800 dark:border-custom-blue700 xlx:px-[18px] xlx:py-[10px] lgx:flex-col">
      <div className="flex flex-col w-[46%] lgx:w-full lgx:mb-[23px]">
        <span className="text-custom-gray800 text-base mb-[6px] font-semibold xlx:mb-[2px] xlx:text-sm dark:text-white">
          Al
        </span>
        {/* row */}
        <div className="border-custom-gray1000 border-[1px] rounded-[8px] h-[48px] flex items-center mb-[20px] xlx:mb-[7px] lgx:mb-[18px] dark:border-custom-blue700 dark:bg-custom-blue900">
          <span className="h-full max-w-[86px] flex items-center justify-center w-full text-white text-base bg-custom-orange rounded-[8px]">
            Fiyat
          </span>
          <input
            type="text"
            className="w-full border-none outline-none text-custom-gray800 h-full px-[10px] bg-transparent dark:text-white"
          />
          <div className="flex items-center pr-[11px]">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[8px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="text-black text-opacity-[0.54] dark:text-white">
              ETH
            </span>
          </div>
        </div>
        {/* row */}
        <div className="border-custom-gray1000 border-[1px] rounded-[8px] h-[48px] flex items-center mb-[3px] dark:border-custom-blue700 dark:bg-custom-blue900">
          <span className="h-full max-w-[86px] flex items-center justify-center w-full text-white text-base bg-custom-orange rounded-[8px]">
            Miktar
          </span>
          <input
            type="text"
            className="w-full border-none outline-none text-custom-gray800 h-full px-[10px] bg-transparent dark:text-white"
          />
          <div className="flex items-center pr-[11px]">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[8px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="text-black text-opacity-[0.54] dark:text-white">
              DTL
            </span>
          </div>
        </div>
        <span className="text-base text-custom-gray500 pb-[4px] xlx:text-sm dark:text-white dark:text-opacity-[0.7]">
          Toplam: 0 ETH
        </span>
        <PercentageSelector />
        <button className="flex justify-center mt-[5px] text-custom-gray600 text-xl py-[9px] shadow-button text-center bg-custom-orange rounded-[8px] w-full xlx:mt-[2px] xlx:text-base xlx:py-[4px] xlx:rounded-[4px]">
          Al
        </button>
      </div>
      <span className="w-[1px] bg-custom-blue400 bg-opacity-[0.14] h-full lgx:hidden dark:bg-white dark:bg-opacity-[0.14]"></span>
      <div className="flex flex-col w-[46%] lgx:w-full lgx:mb-[23px]">
        <span className="text-custom-gray800 text-base mb-[6px] font-semibold xlx:mb-[2px] xlx:text-sm dark:text-white">
          Sat
        </span>
        {/* row */}
        <div className="border-custom-gray1000 border-[1px] rounded-[8px] h-[48px] flex items-center mb-[20px] xlx:mb-[7px] lgx:mb-[18px] dark:border-custom-blue700 dark:bg-custom-blue900">
          <span className="h-full max-w-[86px] flex items-center justify-center w-full text-white text-base bg-custom-orange rounded-[8px]">
            Fiyat
          </span>
          <input
            type="text"
            className="w-full border-none outline-none text-custom-gray800 h-full px-[10px] bg-transparent dark:text-white"
          />
          <div className="flex items-center pr-[11px]">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[8px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="text-black text-opacity-[0.54] dark:text-white">
              ETH
            </span>
          </div>
        </div>
        {/* row */}
        <div className="border-custom-gray1000 border-[1px] rounded-[8px] h-[48px] flex items-center mb-[3px] dark:border-custom-blue700 dark:bg-custom-blue900">
          <span className="h-full max-w-[86px] flex items-center justify-center w-full text-white text-base bg-custom-orange rounded-[8px]">
            Miktar
          </span>
          <input
            type="text"
            className="w-full border-none outline-none text-custom-gray800 h-full px-[10px] bg-transparent dark:text-white"
          />
          <div className="flex items-center pr-[11px]">
            <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[8px] dark:bg-custom-blue500">
              <Ethereum size="15" />
            </span>
            <span className="text-black text-opacity-[0.54] dark:text-white">
              DTL
            </span>
          </div>
        </div>
        <span className="text-base text-custom-gray500 pb-[4px] xlx:text-sm dark:text-white dark:text-opacity-[0.7]">
          Toplam: 0 ETH
        </span>
        <PercentageSelector />
        <button className="flex justify-center mt-[5px] text-custom-blue400 text-opacity-[0.54] border-custom-blue400 border-[1px] border-solid border-opacity-[0.15] text-xl py-[9px] shadow-button text-center bg-white rounded-[8px] w-full lgx:text-base lgx:py-[4px] lgx:rounded-[4px] dark:bg-custom-blue900 dark:text-white dark:border-custom-blue700">
          Sat
        </button>
      </div>
    </div>
  );
};

export default SwapLimited;
