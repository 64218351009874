import * as React from "react";

const Download = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.1818 12.2727C16.9648 12.2727 16.7567 12.3589 16.6033 12.5123C16.4498 12.6658 16.3636 12.8739 16.3636 13.0909V16.3636H1.63636V13.0909C1.63636 12.8739 1.55016 12.6658 1.39672 12.5123C1.24328 12.3589 1.03518 12.2727 0.818182 12.2727C0.601187 12.2727 0.393079 12.3589 0.23964 12.5123C0.086201 12.6658 0 12.8739 0 13.0909V17.1818C0 17.3988 0.086201 17.6069 0.23964 17.7603C0.393079 17.9138 0.601187 18 0.818182 18H17.1818C17.3988 18 17.6069 17.9138 17.7604 17.7603C17.9138 17.6069 18 17.3988 18 17.1818V13.0909C18 12.8739 17.9138 12.6658 17.7604 12.5123C17.6069 12.3589 17.3988 12.2727 17.1818 12.2727Z"
      fill={fill}
      fill-opacity={fillOpacity}
    />
    <path
      d="M8.42099 13.6694C8.57442 13.8227 8.78249 13.9089 8.99944 13.9089C9.2164 13.9089 9.42447 13.8227 9.5779 13.6694L14.487 8.76027C14.636 8.60596 14.7185 8.39929 14.7166 8.18476C14.7148 7.97024 14.6287 7.76503 14.477 7.61333C14.3253 7.46163 14.1201 7.37559 13.9056 7.37372C13.6911 7.37186 13.4844 7.45432 13.3301 7.60336L9.81763 11.1158V0.818182C9.81763 0.601186 9.73143 0.393079 9.57799 0.23964C9.42455 0.086201 9.21644 0 8.99944 0C8.78245 0 8.57434 0.086201 8.4209 0.23964C8.26746 0.393079 8.18126 0.601186 8.18126 0.818182V11.1158L4.66881 7.60336C4.5145 7.45432 4.30782 7.37186 4.0933 7.37372C3.87877 7.37559 3.67356 7.46163 3.52187 7.61333C3.37017 7.76503 3.28412 7.97024 3.28226 8.18476C3.28039 8.39929 3.36286 8.60596 3.5119 8.76027L8.42099 13.6694Z"
      fill={fill}
      fill-opacity={fillOpacity}
    />
  </svg>
);

export default Download;
