import React from "react";
import { SET_THEME } from "../actions/homeAction";


const initialState = {
  theme: 'light'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
        return {
            theme: action.theme
        }
    default:
      return state;
  }
};
