import * as React from "react";

const Plus = ({ className, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.75001 0H5.25V5.24999H0V6.75001H5.25V12H6.75001V6.75001H12V5.24999H6.75001V0Z"
      fill={fill}
    />
  </svg>
);

export default Plus;
