import PropTypes from "prop-types";
import Step from "./Step";

const PercentageSelector = ({ onChange, className, items }) => {
  return (
    <div
      className={`w-full relative flex items-center justify-between cursor-pointer ${className}`}
    >
      <div className="pb-[17px] flex items-center w-full after:block after:w-full after:h-[3px] after:bg-custom-orange200 after:m-[-1px]">
        <Step checked label="0%" />
      </div>
      <div className="pb-[17px] flex items-center w-full after:block after:w-full after:h-[3px] after:bg-custom-orange200 after:m-[-1px]">
        <Step checked label="25%" />
      </div>
      <div className="pb-[17px] flex items-center w-full after:block after:w-full after:h-[3px] after:bg-custom-orange200 after:m-[-1px]">
        <Step checked label="50%" />
      </div>
      <div className="pb-[17px] flex items-center w-full after:block after:w-full after:h-[3px] after:bg-custom-orange200 after:m-[-1px]">
        <Step checked={false} label="75%" />
      </div>
      <div className="pb-[17px]">
        <Step checked={false} label="100%" />
      </div>
    </div>
  );
};

PercentageSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default PercentageSelector;
