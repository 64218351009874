import * as React from "react";

const Hexagon = ({ fill, fillOpacity, className, size }) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.2426 1.33004L16.9117 7.67104C17.0294 7.87464 17.0294 8.12467 16.9117 8.32842L13.2425 14.67C13.2425 14.67 13.2424 14.6701 13.2424 14.6702C13.1243 14.8741 12.9062 15 12.6692 15H5.33057C5.09376 15 4.87554 14.8741 4.75706 14.6696L1.0883 8.32838C1.08824 8.32828 1.08818 8.32818 1.08813 8.32808C1.08811 8.32805 1.0881 8.32802 1.08808 8.328C0.970622 8.12446 0.970663 7.87483 1.08806 7.67144C1.08811 7.67137 1.08815 7.67129 1.08819 7.67121C1.08822 7.67117 1.08824 7.67113 1.08827 7.67108L4.75706 1.33032C4.87552 1.12586 5.09372 1 5.33057 1H12.6693C12.9063 1 13.1244 1.12599 13.2426 1.33004Z"
      fill="white"
      stroke="#ECAF9D"
      stroke-width="2"
    />
  </svg>
);

export default Hexagon;
