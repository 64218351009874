import { Calendar } from "src/commons/Icons";
import ChartDropdown from "src/components/ui/dropdown/ChartDropdown";
import useWindowSize from "src/utils/useWindowSize";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { areaOptions, candleOptions } from "src/data/chartsData";
import { useSelector } from "react-redux";

const ChartComp = props => {
  const {currencyOne='ETH', currencyTwo='USDT', type} = props;
  const { width } = useWindowSize();
  const [chartType, setChartType] = useState(type ? type : "candle");
  const {theme} = useSelector(state => state.home);

  return (
    <div  style={{overflow: 'hidden'}} className="w-full h-full bg-custom-gray300 flex flex-col justify-between dark:bg-custom-blue800">
      <div className="">
       
      <iframe id="iframeTradingView" height={380} width={'100%'} src={`https://tradingview.defimec.com/?symbol=${currencyOne}/${currencyTwo}&theme=${theme}`} />     
       
      </div>
    </div>
  );
};

export default ChartComp;
