import * as React from "react";

const ThreeDots = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="1.25" cy="1" r="0.75" fill={fill} fillOpacity={fillOpacity} />
    <circle cx="4.25" cy="1" r="0.75" fill={fill} fillOpacity={fillOpacity} />
    <circle cx="7.25" cy="1" r="0.75" fill={fill} fillOpacity={fillOpacity} />
  </svg>
);

export default ThreeDots;
