import { Logo, Ethereum, Ava, Bitcoin } from "src/commons/Icons";
import { Link } from "react-router-dom";
import Navigation from "src/components/ui/Navigation";
import { SideNavbar } from "src/components/composite";
import useWindowSize from "src/utils/useWindowSize";

const Navbar = () => {
  const { width } = useWindowSize();

  if (width < 1024) {
    return (
      <div className="flex flex-col px-[14px] py-[12px] relative border-b-[1px] border-solid border-custom-gray200 overflow-hidden dark:border-custom-blue700">
        <div className="flex justify-between items-center mb-[12px]">
          <Link to="/" className="mr-[61px]">
            <Logo className="w-[100px] h-[46px]" />
          </Link>
          <SideNavbar />
        </div>
        <Navigation />
      </div>
    );
  }

  if (width < 1280) {
    return (
      <div className="flex items-center justify-between px-[32px] py-[18px] relative">
        <Link to="/" className="mr-[61px]">
          <Logo />
        </Link>
        <div className="grid gap-y-[12px]">
          <SideNavbar />
          <Navigation />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center px-[32px] py-[18px] relative">
        <Link to="/" className="mr-[61px]">
          <Logo />
        </Link>
        <div className="flex items-center">
          <span className="font-normal text-[19px] text-custom-blue100 dark:text-white">
            NETWORK
          </span>
          <div className="h-[14px] w-[1.5px] bg-custom-blue200 mx-[13px] rounded-full dark:bg-white dark:bg-opacity-[0.45]"></div>
          <div className="grid gap-[4px] grid-flow-col border-opacity-[0.15] border-custom-blue400 border-solid rounded-[4px] border-[1px] h-[24px] w-[78px] mr-[12px] dark:border-white dark:border-opacity-[0.34]">
            <label className="relative cursor-pointer w-[24px] h-[24px] flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
              <input
                type="radio"
                name="coinType"
                id="eth"
                className="hidden peer"
                defaultChecked
              />
              <div className="w-full h-full peer-checked:bg-custom-blue100 flex items-center justify-center dark:peer-checked:bg-custom-blue500">
                <Ethereum size={18} />
              </div>
            </label>
            <label className="relative cursor-pointer w-[24px] h-[24px] flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
              <input
                type="radio"
                name="coinType"
                id="ava"
                className="hidden peer"
              />
              <div className="w-full h-full peer-checked:bg-custom-blue100 flex items-center justify-center dark:peer-checked:bg-custom-blue500">
                <Ava size={22} fill="#002C3E" fillOpacity="0.54" />
              </div>
            </label>
            <label className="relative cursor-pointer w-[24px] h-[24px] flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
              <input
                type="radio"
                name="coinType"
                id="btc"
                className="hidden peer"
              />
              <div className="w-full h-full peer-checked:bg-custom-blue100 flex items-center justify-center dark:peer-checked:bg-custom-blue500">
                <Bitcoin size={16} fill="#002C3E" fillOpacity="0.54" />
              </div>
            </label>
          </div>
          <span className="font-normal text-[19px] text-custom-blue100 dark:text-white">
            ETH
          </span>
        </div>
        <Navigation />
      </div>
      <div className="absolute top-[106px] left-0 h-full flex items-center mt-[-106px]">
        <SideNavbar />
      </div>
    </div>
  );
};

export default Navbar;
