import * as React from "react";

const StarFilled = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.0365229 4.91254C0.128169 4.63786 0.379609 4.44277 0.677086 4.41679L4.71786 4.06127L6.31569 0.437487C6.43351 0.171911 6.70183 0 6.99994 0C7.29806 0 7.56638 0.171911 7.68419 0.438108L9.28203 4.06127L13.3234 4.41679C13.6204 4.44339 13.8712 4.63786 13.9634 4.91254C14.0555 5.18723 13.9704 5.48851 13.7458 5.67843L10.6914 8.27396L11.5921 12.1182C11.658 12.4008 11.5448 12.693 11.3027 12.8626C11.1726 12.9536 11.0204 13 10.8669 13C10.7346 13 10.6033 12.9654 10.4855 12.8971L6.99994 10.8786L3.51567 12.8971C3.2607 13.0457 2.9393 13.0322 2.69779 12.8626C2.45565 12.6925 2.34253 12.4002 2.40843 12.1182L3.30909 8.27396L0.254744 5.67895C0.0301132 5.48851 -0.0556574 5.18775 0.0365229 4.91254Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default StarFilled;
