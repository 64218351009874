import * as React from "react";

const MenuClose = ({ fill, fillOpacity, className }) => (
  <svg
    width="23"
    height="25"
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14.9987C0 14.5567 0.173086 14.1327 0.481182 13.8202C0.789277 13.5076 1.20714 13.332 1.64286 13.332H21.3571C21.7929 13.332 22.2107 13.5076 22.5188 13.8202C22.8269 14.1327 23 14.5567 23 14.9987C23 15.4407 22.8269 15.8646 22.5188 16.1772C22.2107 16.4898 21.7929 16.6654 21.3571 16.6654H1.64286C1.20714 16.6654 0.789277 16.4898 0.481182 16.1772C0.173086 15.8646 0 15.4407 0 14.9987Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 23.332C0 22.89 0.173086 22.4661 0.481182 22.1535C0.789277 21.841 1.20714 21.6654 1.64286 21.6654H21.3571C21.7929 21.6654 22.2107 21.841 22.5188 22.1535C22.8269 22.4661 23 22.89 23 23.332C23 23.7741 22.8269 24.198 22.5188 24.5105C22.2107 24.8231 21.7929 24.9987 21.3571 24.9987H1.64286C1.20714 24.9987 0.789277 24.8231 0.481182 24.5105C0.173086 24.198 0 23.7741 0 23.332Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M19.443 9.22633L19.4431 9.22635C19.6783 9.41024 19.9762 9.5 20.2684 9.5C20.5606 9.5 20.8585 9.41024 21.0937 9.22635C21.3318 9.04018 21.5 8.75757 21.5 8.42797C21.5 8.09838 21.3318 7.81577 21.0937 7.6296L12.3258 0.774324C12.3258 0.774301 12.3257 0.774279 12.3257 0.774256C12.208 0.682029 12.0743 0.613432 11.9344 0.56803C11.7943 0.522579 11.6469 0.5 11.5 0.5C11.3531 0.5 11.2057 0.522579 11.0656 0.56803C10.9257 0.613434 10.792 0.682033 10.6743 0.774262C10.6743 0.774283 10.6742 0.774304 10.6742 0.774324L1.90632 7.6296C1.6682 7.81577 1.5 8.09837 1.5 8.42797C1.5 8.75758 1.6682 9.04018 1.90632 9.22635C2.14151 9.41024 2.43943 9.5 2.73162 9.5C3.02382 9.5 3.32174 9.41024 3.55693 9.22635L3.55696 9.22633L11.5 3.01488L19.443 9.22633Z"
      fill={fill}
      stroke={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default MenuClose;
