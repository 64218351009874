import MINABI from "../abis/minAbi.json";
import { useMultipleContractSingleData } from "./contract";
import { Interface } from "@ethersproject/abi";
import { useEffect, useMemo, useState } from "react";
import { isAddress } from "@ethersproject/address";
import { useActiveWeb3React } from "./web3";
import { DEFAULT_LIST_OF_LISTS } from "../constants/lists";
import { readJson } from "../utils/method";
import { walletconnect } from "src/hooks/connectors";

export function useValidateTokensAndCallMultiCall() {
  const { account, chainId, active, connector } = useActiveWeb3React();
  const [tokens, setTokens] = useState();
  useEffect(() => {
    const readJsonAsync = async () => {
      const tokensJson = await readJson(
        DEFAULT_LIST_OF_LISTS.DEFIMEC_TOKEN_LIST
      );
      if (chainId > 0 && account !== undefined)
        setTimeout(() => {
          setTokens(tokensJson);
        }, 2000);
    };
    readJsonAsync();
  }, [chainId]);
  const validatedTokens = useMemo(
    () =>
      tokens?.tokens?.filter(
        (t) => isAddress(t?.address) !== false && t?.chainId === chainId
      ) ?? [],
    [tokens, chainId, account]
  );
  const validatedTokenAddresses = useMemo(
    () => validatedTokens.map((vt) => vt.address),
    [validatedTokens, account, active]
  );
  const ERC20Interface = new Interface(MINABI);
  console.log("aksdaksjdjksahjkdhsajkhdjksajkdhsa:", active);

  let balances = useMultipleContractSingleData(
    validatedTokens,
    validatedTokenAddresses,
    ERC20Interface,
    "balanceOf",
    [account],
    undefined,
    100_000
  );
  balances = useMultipleContractSingleData(
    validatedTokens,
    validatedTokenAddresses,
    ERC20Interface,
    "balanceOf",
    [account],
    undefined,
    100_000
  );

  return balances;
}
