const Download = () => {
  return (
    <div className="w-full h-full bg-download bg-cover flex items-center rounded-[8px] overflow-hidden xlx:flex-col lgx:flex-row">
      <div className="w-[50%] h-full bg-downloadSide bg-cover bg-center xlx:w-full lgx:w-[50%]"></div>
      <div className="text-white flex flex-col pr-[20px] xlx:pr-[unset]">
        <span className="lgx:text-xs">Android ve Ios Uygulamamizi</span>
        <p className="flex flex-col xlx:flex-row lgx:flex-col lgx:text-xs">
          <span className="xlx:mr-[2px] lgx:mr-[unset]">App Marketlerden</span>
          <span>İndirebilirsiniz</span>
        </p>
        <div className="grid grid-cols-2 grid-rows-[37px] gap-[12px] mt-[20px] xlx:gap-[8px] xlx:grid-rows-[46px] xlx:mt-[10px] lgx:grid-rows-[27px] lgx:gap-[12px] lgx:mt-[20px] ">
          <div className="bg-appStore bg-cover"></div>
          <div className="bg-googlePlay bg-cover"></div>
        </div>
      </div>
    </div>
  );
};

export default Download;
