import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Swap,
  Wallet,
  ListPrice,
  TransactionSummary,
  Chart as ChartComp,
  Download,
  SwapLimited,
} from "src/components/view/Cards";
import { ImportWallet } from "src/components/view/Modals";

const Home = (props) => {
  const location = useLocation();
  const [currencyOne, setCurrencyOne] = useState("BTC");
  const [currencyTwo, setCurrencyTwo] = useState("USDT");

  const context = useWeb3React();
  const { active } = context;

  return (
    <div className="mr-[30px] grid grid-cols-homeWeb justify-center gap-4 grid-rows-homeWeb xlx:grid-rows-homeTablet xlx:gap-y-3 xlx:mx-[unset] xlx:grid-cols-homeTablet lgx:grid-cols-1 lgx:grid-rows-homeMobile lgx:mx-[13px]">
      <div className="shadow-homeCard xlx:order-3 xlx:col-span-2 lgx:order-2 lgx:col-span-1">
      {location.pathname === "/" ? <Swap /> : <SwapLimited />}
        
        {/* <Swap /> */}
      </div>
      <div className="shadow-homeCard xlx:order-1">
        {active ? <Wallet {...props} /> : 
        <ImportWallet {...props} />}
      </div>
      <div className="shadow-homeCard xlx:order-4 xlx:col-span-2 lgx:col-span-1 border-custom-gray400 border-solid border-[1px] rounded dark:border-custom-blue700">
        <ChartComp currencyOne={currencyOne} />
      </div>
      <div className="shadow-homeCard xlx:order-6 xlx:col-span-2 lgx:order-5 lgx:col-span-1">
        <ListPrice setCurrencyOne={setCurrencyOne} />
      </div>
      <div className="shadow-homeCard xlx:order-5 xlx:col-span-2 lgx:order-6 lgx:col-span-1">
        <TransactionSummary />
      </div>
      <div className="shadow-homeCard xlx:order-2 lgx:order-3">
        <Download />
      </div>
    </div>
  );
};

export default Home;
