import SwapDropdown from "src/components/ui/dropdown/SwapDropdown";
import { ArrowLine } from "src/commons/Icons";
const Transfer = () => {
  return (
    <div className="mt-[70px] mr-[30px] xlx:mx-[63px] lgx:mx-[13px]">
      <h1 className="text-custom-blue100 text-[32px] mb-[19px] text-center lgx:text-[20px] dark:text-white">
        Kripto Varlık Transferlerinizin <b>En Hızlı Ve Basit Yolu</b>
      </h1>
      <div className="flex flex-col items-center justify-center rounded-[8px] boxShadow-homeCard border-solid border-custom-gray400 border-[1px] bg-custom-gray300 min-h-[380px] px-[32px] lgx:min-h-[300px] dark:bg-custom-blue800 dark:border-custom-blue1300">
        <div className="w-full mb-[33px] flex items-center justify-center mt-[36px] xlx:mt-[8px] lgx:flex-col lgx:mt-[unset] lgx:mb-[16px]">
          <div className="w-[45%] flex flex-col items-start lgx:w-full">
            <span className="text-custom-blue400 text-opacity-[0.6] text-base mb-[6px] font-semibold lgx:text-sm lgx:mb-[2px] dark:text-white">
              İtibaren
            </span>
            <div className="relative w-full border-solid border-[0.8px] border-custom-blue400 border-opacity-[0.20] rounded-[8px] px-[20px] py-[9px] flex justify-between items-center bg-white lgx:px-[12px] dark:bg-custom-blue900 dark:border-custom-blue1000">
              <input
                type="text"
                className="w-full h-full text-custom-blue400 text-opacity-[0.54] text-[18px] bg-transparent outline-none lgx:text-[14px] lgx:text-custom-blue100 dark:text-white"
                defaultValue={140}
              />
              <SwapDropdown width="78px" />
            </div>
          </div>
          <span>
            <ArrowLine className="fill-[rgba(0,44,62,0.54)] w-[24px] mx-[35px] mt-[30px] lgx:mt-[22px] lgx:mb-[0px] lgx:mx-[unset] lgx:rotate-90 dark:fill-[#738B95]" />
          </span>
          <div className="w-[45%] flex flex-col items-start lgx:w-full">
            <span className="text-custom-blue400 text-opacity-[0.6] text-base mb-[6px] font-semibold lgx:text-sm lgx:mb-[2px] dark:text-white">
              Adrese
            </span>
            <div className="relative w-full border-solid border-[0.8px] border-custom-blue400 border-opacity-[0.20] rounded-[8px] px-[20px] py-[9px] flex justify-between items-center bg-white lgx:px-[12px] dark:bg-custom-blue900 dark:border-custom-blue1000">
              <input
                type="text"
                className="w-full h-full text-custom-blue400 text-opacity-[0.54] text-[18px] bg-transparent outline-none lgx:text-[14px] lgx:text-custom-blue100 dark:text-white"
                defaultValue={140}
              />
              <SwapDropdown width="78px" />
            </div>
          </div>
        </div>
        <button className="flex justify-center text-custom-gray600 text-xl py-[12px] mb-[12px] shadow-button text-center bg-custom-orange rounded-[8px] w-[318px] xlx:mb-[12px] xlx:w-[342px] lgx:w-full lgx:rounded-[4px]">
          Cüzdanı Bağla
        </button>
        <p className="text-xs text-custom-blue400 text-opacity-[0.54] text-center w-[318px] xlx:w-[342px] lgx:w-full dark:text-white">
          Deximec'i Kullanarak{" "}
          <span className="font-semibold text-custom-blue300">
            Şartlar Ve Koşulları
          </span>{" "}
          Kabul Etmiş Olursunuz.
        </p>
      </div>
    </div>
  );
};

export default Transfer;
