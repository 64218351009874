import { Hexagon, HexagonFilled } from "src/commons/Icons";
import PropTypes from "prop-types";

const Step = ({ id, onChange, className, checked, label }) => {
  return (
    <div
      onClick={() => onChange(id)}
      className={`relative flex items-center cursor-pointer ${className}`}
    >
      {checked ? <HexagonFilled /> : <Hexagon />}
      <span className="absolute bottom-[-17px] left-[50%] translate-x-[-50%] text-[10px] leading-[15px] text-black text-opacity-[0.54] dark:text-white dark:text-opacity-[0.7]">
        {label}
      </span>
    </div>
  );
};

Step.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default Step;
