import {
  Notification,
  Moon,
  Message,
  Arrow,
  Search,
  MenuOpen,
  MenuClose,
} from "src/commons/Icons";
import Radio from "src/components/ui/input/Radio";
import useWindowSize from "src/utils/useWindowSize";
import useThemeMode from "src/utils/useThemeMode";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SET_THEME } from "src/store/actions/homeAction";

const SideNavbar = () => {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const { theme, setTheme } = useThemeMode();
  const dispatch = useDispatch();

  if (width < 1024) {
    return (
      <div className="">
        <div className="grid grid-flow-col gap-[8px]">
          <Search
            size="17"
            className="fill-[rgba(0,44,62,0.54)] dark:fill-[rgba(255,255,255,0.6)]"
          />
          <span
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MenuOpen className="fill-[rgba(0,44,62,0.54)] dark:fill-[rgba(255,255,255,0.6)]" />
          </span>
        </div>
        <div
          className={`transition-custom z-50 bg-custom-blue100 w-[205px] h-full top-0 fixed pl-[18px] pr-[14px] pt-[23px] ${
            isOpen ? "translate-x-[-147px]" : "translate-x-[100px]"
          } dark:bg-custom-blue400`}
        >
          <div className="flex justify-end items-center">
            <Search
              fill="white"
              fillOpacity="0.6"
              size="22"
              className="mr-[11px]"
            />
            <span
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <MenuClose fill="#68B2F7" fillOpacity="1" />
            </span>
          </div>
          <div className="grid grid-flow-row gap-[39px] pt-[33px] justify-self-end">
            <div className="flex items-center">
              <div className="cursor-pointer w-[35px] h-[35px] flex items-center justify-center rounded-full mr-[5px] hover:bg-custom-blue300 transition-custom">
                <Notification size="30" fillOpacity="1" fill="white" />
              </div>
              <span className="text-[13px] text-white/60">Bildirimler</span>
            </div>
            <div className="flex items-center">
              <div
                onClick={() => {
                  let newTheme = theme === "light" ? "dark" : "light";
                  setTheme(newTheme);
                  dispatch({
                    type: SET_THEME,
                    theme: newTheme
                  })
                }}
                className="cursor-pointer w-[35px] h-[35px] flex items-center justify-center rounded-full mr-[5px] hover:bg-custom-blue300 transition-custom"
              >
                <Moon size="20" fillOpacity="1" fill="white" />
              </div>
              <span className="text-[13px] text-white/60">Karanlık Mod</span>
            </div>
            <div className="flex items-center">
              <div className="cursor-pointer w-[35px] h-[35px] flex items-center justify-center rounded-full mr-[5px] hover:bg-custom-blue300 transition-custom">
                <Message size="20" fillOpacity="1" fill="white" />
              </div>
              <span className="text-[13px] text-white/60">Canlı Yardım</span>
            </div>
            <div className="flex items-center">
              <div className="cursor-pointer w-[35px] h-[35px] flex items-center justify-center mr-[2px]">
                <span className=" text-[15px] text-white">SSS</span>
              </div>
              <span className="text-[13px] text-white/60 truncate">
                Sıkça Sorulan Sorular
              </span>
            </div>
            <div className="grid grid-flow-col gap-[11px] pl-[7.5px] w-min">
              <Radio
                label="EN"
                id="en"
                name="lang"
                onChange={(e) => {
                  console.log(e.target.id);
                }}
              />
              <Radio
                label="FR"
                id="fr"
                name="lang"
                onChange={(e) => {
                  console.log(e.target.id);
                }}
              />{" "}
              <Radio
                label="TR"
                id="tr"
                name="lang"
                onChange={(e) => {
                  console.log(e.target.id);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (width < 1280) {
    return (
      <div className="grid grid-flow-col gap-[39px] px-[19px] w-min justify-self-end">
        <div className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom hover:w-[164px]">
          <span className="text-[15px] pr-[4.5px] text-black/[0.54] group-hover:hidden dark:text-[rgba(255,255,255,0.69)]">
            TR
          </span>
          <Arrow
            size="9"
            className="fill-[rgba(0,0,0,0.54)] group-hover:hidden dark:fill-[rgba(255,255,255,0.69)]"
          />
          <div className="hidden group-hover:grid grid-flow-col gap-[11px]">
            <Radio
              label="EN"
              id="en"
              name="lang"
              onChange={(e) => {
                console.log(e.target.id);
              }}
            />
            <Radio
              label="FR"
              id="fr"
              name="lang"
              onChange={(e) => {
                console.log(e.target.id);
              }}
            />{" "}
            <Radio
              label="TR"
              id="tr"
              name="lang"
              onChange={(e) => {
                console.log(e.target.id);
              }}
            />
          </div>
        </div>
        <div className="group cursor-pointer relative w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom hover:w-[217px]">
          <span className="ml-[7.75px] text-[15px] text-black/[0.54] group-hover:text-white group-hover:font-semibold group-hover:mr-[9px] transition-custom dark:text-[rgba(255,255,255,0.69)]">
            SSS
          </span>
          <span className="opacity-0 group-hover:mr-[7.75px] truncate text-white text-[15px] group-hover:block group-hover:opacity-100 transition-custom ">
            Sıkça Sorulan Sorular
          </span>
        </div>
        <div className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom">
          <Message
            size={24}
            fillOpacity="1"
            className="fill-[rgba(0,0,0,0.54)] group-hover:fill-[white] group-hover:fill-opacity-[1] transition-custom dark:fill-[rgba(255,255,255,0.69)]"
          />
        </div>
        <div
          onClick={() => {
            let newTheme = theme === "light" ? "dark" : "light";
            setTheme(newTheme);
            dispatch({
              type: SET_THEME,
              theme: newTheme
            })
          }}
          className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom"
        >
          <Moon
            size={24}
            fillOpacity="1"
            className="fill-[rgba(0,0,0,0.54)] group-hover:fill-[white] group-hover:fill-opacity-[1] transition-custom dark:fill-[rgba(255,255,255,0.69)]"
          />
        </div>
        <div className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom">
          <Notification
            size={33}
            fillOpacity="1"
            className="fill-[rgba(0,0,0,0.54)] group-hover:fill-[white] group-hover:fill-opacity-[1] transition-custom dark:fill-[rgba(255,255,255,0.69)]"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="grid grid-flow-row gap-[41px] px-[19px] z-[1]">
      <div className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom">
        <Notification
          fillOpacity="1"
          size={33}
          className="fill-[rgba(0,0,0,0.54)] group-hover:fill-[white] group-hover:fill-opacity-[1] transition-custom dark:fill-[rgba(255,255,255,0.69)]"
        />
      </div>
      <div
        onClick={() => {
          let newTheme = theme === "light" ? "dark" : "light";
          setTheme(newTheme);
          dispatch({
            type: SET_THEME,
            theme: newTheme
          })
        }}
        className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom"
      >
        <Moon
          size={24}
          fillOpacity="1"
          className="fill-[rgba(0,0,0,0.54)] group-hover:fill-[white] group-hover:fill-opacity-[1] transition-custom dark:fill-[rgba(255,255,255,0.69)]"
        />
      </div>
      <div className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom">
        <Message
          size={24}
          fillOpacity="1"
          className="fill-[rgba(0,0,0,0.54)] group-hover:fill-[white] group-hover:fill-opacity-[1] transition-custom dark:fill-[rgba(255,255,255,0.69)]"
        />
      </div>
      <div className="group cursor-pointer relative w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom hover:w-[217px]">
        <span className="ml-[7.75px] text-[15px] text-black/[0.54] group-hover:text-white group-hover:font-semibold group-hover:mr-[9px] transition-custom dark:text-[rgba(255,255,255,0.69)]">
          SSS
        </span>
        <span className="opacity-0 group-hover:mr-[7.75px] truncate text-white text-[15px] group-hover:block group-hover:opacity-100 transition-custom">
          Sıkça Sorulan Sorular
        </span>
      </div>
      <div className="group cursor-pointer w-[42px] h-[42px] flex items-center justify-center rounded-full hover:bg-custom-blue300 transition-custom hover:w-[164px]">
        <span className="text-[15px] pr-[4.5px] text-black/[0.54] group-hover:hidden dark:text-[rgba(255,255,255,0.69)]">
          TR
        </span>
        <Arrow
          size="9"
          className="fill-[rgba(0,0,0,0.54)] group-hover:hidden dark:fill-[rgba(255,255,255,0.69)]"
        />
        <div className="hidden group-hover:grid grid-flow-col gap-[11px]">
          <Radio
            label="EN"
            id="en"
            name="lang"
            onChange={(e) => {
              console.log(e.target.id);
            }}
          />
          <Radio
            label="FR"
            id="fr"
            name="lang"
            onChange={(e) => {
              console.log(e.target.id);
            }}
          />{" "}
          <Radio
            label="TR"
            id="tr"
            name="lang"
            onChange={(e) => {
              console.log(e.target.id);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
