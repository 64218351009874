import { useEffect, useMemo, useState } from "react";
import ERC20_ABI from "../abis/erc20.json";
import { useActiveWeb3React } from "./web3";
import MULTICALL_ABI from "../abis/multicall2.json";
import { constructSameAddressMap } from "../constants/chains";
import { SupportedChainId } from "../constants/chains";
import { BigNumber } from "@ethersproject/bignumber";
import { isEmpty } from "src/utils/method";
import { toCallState } from "../utils/multiUtilCallMethods";
import { getContract } from "../utils/method";
export const MULTICALL2_ADDRESSES = {
  ...constructSameAddressMap("0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696"),
  [SupportedChainId.ARBITRUM_ONE]: "0x021CeAC7e681dBCE9b5039d2535ED97590eB395c",
  [SupportedChainId.ARBITRUM_RINKEBY]:
    "0x334f67349c1cB3A8fF1268c3eC43FF1D3De246C6",
};

export function useMulticall2Contract() {
  return useContract(MULTICALL2_ADDRESSES, MULTICALL_ABI, false);
}

// returns null on errors
export function useContract(
  addressOrAddressMap,
  ABI,
  withSignerIfPossible = true
) {
  const { library, account, chainId } = useActiveWeb3React();
  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address = "";
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account,
  ]);
}

export function useTokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

function isMethodArg(x) {
  return (
    BigNumber.isBigNumber(x) || ["string", "number"].indexOf(typeof x) !== -1
  );
}

function isValidMethodArgs(x) {
  return (
    x === undefined ||
    (Array.isArray(x) &&
      x.every(
        (xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))
      ))
  );
}

/**
 * Fetches a chunk of calls, enforcing a minimum block number constraint
 * @param multicall2Contract multicall contract to fetch against
 * @param chunk chunk of calls to make
 * @param minBlockNumber minimum block number of the result set
 */
export async function fetchChunk(multicall2Contract, chunk, minBlockNumber) {
  let resultsBlockNumber;
  let results;
  try {
    const { blockNumber, returnData } =
      await multicall2Contract.callStatic.tryBlockAndAggregate(
        false,
        chunk.map((obj) => ({ target: obj.address, callData: obj.callData }))
      );
    resultsBlockNumber = blockNumber.toNumber();
    results = returnData;
  } catch (error) {
    console.debug("Failed to fetch chunk", error);
    throw error;
  }
  if (resultsBlockNumber < minBlockNumber) {
    const retryMessage = `Fetched results for old block number: ${resultsBlockNumber.toString()} vs. ${minBlockNumber}`;
    console.debug(retryMessage);
  }
  return { results, blockNumber: resultsBlockNumber };
}

export function useMultipleContractSingleData(
  tokens,
  addresses,
  contractInterface,
  methodName,
  callInputs,
  options,
  gasRequired
) {
  const multicall2Contract = useMulticall2Contract();
  const [list, setList] = useState();
  // const dispatch = useDispatch();
  const fragment = useMemo(
    () => contractInterface.getFunction(methodName),
    [contractInterface, methodName]
  );
  const callData = useMemo(
    () =>
      fragment && isValidMethodArgs(callInputs)
        ? contractInterface.encodeFunctionData(fragment, callInputs)
        : undefined,
    [callInputs, contractInterface, fragment]
  );
  console.log(callInputs);
  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses.map((address) => {
            return address && callData
              ? {
                  address,
                  callData,
                  ...(gasRequired ? { gasRequired } : {}),
                }
              : undefined;
          })
        : [],
    [addresses, callData, fragment, gasRequired]
  );

  useEffect(() => {
    // console.log('useMemo:@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ' , calls);
    const getResults = async () => {
      const fetchResult = await fetchChunk(multicall2Contract, calls);
      // let blockNumber = await multicall2Contract.functions.getBlockNumber();
      const formattedBalance = fetchResult?.results?.map((result) =>
        toCallState(result, contractInterface, fragment, "latestBlockNumber")
      );
      formattedBalance.map((res, index) => {
        tokens[index]["balance"] =
          res?.result?.balance?.toString() / 10 ** tokens[index]?.decimals;
      });
      // dispatch({
      //   type: SET_BALANCES,
      //   balances: tokens,
      // });
      setList(tokens);
    };
    if (!isEmpty(calls)) getResults();
  }, [addresses, callData, methodName, gasRequired]);
  return list;
  // const latestBlockNumber = useBlockNumber()
}
