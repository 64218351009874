import { ArrowDouble } from "src/commons/Icons";

const PriceRange = props => {
  const {currencyOne, currencyTwo} = props;
  return (
    <div className="w-full h-full flex flex-col items-center px-[16px] pt-[26px] pb-[19px] bg-custom-gray300 border-solid border-[1px] rounded-lg border-custom-gray400 shadow-homeCard dark:bg-custom-blue800 dark:border-custom-blue1300">
      <div className="w-full flex justify-between mb-[18px]">
        <h1 className="text-custom-blue400 text-base dark:text-white">
          Fiyat Aralığı
        </h1>
        <h1 className="text-custom-blue400 text-base dark:text-white">
          {currencyOne} Fiyat | {currencyTwo} Fiyat
        </h1>
      </div>
      <div className="w-full flex h-full lgx:flex-col">
        <div className="w-full flex flex-col items-center justify-center bg-custom-gray1100 shadow-homeCard border-solid border-[1px] border-custom-gray400 rounded-lg lgx:py-[11px] dark:bg-custom-blue900 dark:border-custom-blue700">
          <span className="text-custom-blue400 text-lg mb-[2px] dark:text-white/80 lgx:text-base">
            Minimum Fiyat
          </span>
          <span className="text-2xl text-custom-blue400 text-opacity-[0.54] font-semibold mb-[1px] dark:text-white lgx:text-base">
            0.00043681
          </span>
          <span className="text-base text-custom-blue400 text-opacity-[0.54] font-semibold dark:text-white/60">
          {currencyOne} | {currencyTwo}
          </span>
        </div>
        <span className="self-center mx-[30px] lgx:rotate-90 lgx:my-[11px]">
          <ArrowDouble
            size="24"
            className="fill-[#284862] dark:fill-[#68B2F7]"
          />
        </span>
        <div className="w-full flex flex-col items-center justify-center bg-custom-gray1100 shadow-homeCard border-solid border-[1px] border-custom-gray400 rounded-lg lgx:py-[11px] dark:bg-custom-blue900 dark:border-custom-blue700">
          <span className="text-custom-blue400 text-lg mb-[2px] dark:text-white/80 lgx:text-base">
            Maksimum Fiyat
          </span>
          <span className="text-2xl text-custom-blue400 text-opacity-[0.54] font-semibold mb-[1px] dark:text-white lgx:text-base">
            0.00043681
          </span>
          <span className="text-base text-custom-blue400 text-opacity-[0.54] font-semibold dark:text-white/60">
          {currencyOne} | {currencyTwo}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PriceRange;
