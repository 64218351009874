import { ArrowDouble, Ethereum, Search, Arrow, Plus } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";

const MyPositions = () => {
  const { width } = useWindowSize();

  return (
    <div className="w-full h-full flex items-baseline xlx:flex-col lgx:flex-col-reverse">
      <div className="flex flex-1 items-center justify-between border-solid border-[1px] border-custom-gray400 rounded-lg py-[13px] px-[20px] xlx:w-full lgx:flex-col lgx:items-start dark:bg-custom-blue800 dark:border-custom-blue700">
        <div className="flex flex-col justify-between">
          <h1 className="text-sm font-semibold text-custom-gray800 dark:text-white">
            Pozisyonlarınız (1)
          </h1>
          <div className="flex items-center mt-[8px]">
            <span className="flex items-center justify-center rounded-full bg-custom-blue100 p-[2.5px] mr-[3px] dark:bg-custom-blue300">
              <Ethereum size="15" />
            </span>
            <span className="flex items-center justify-center rounded-full bg-custom-blue100 p-[2.5px] dark:bg-custom-blue300">
              <Ethereum size="15" />
            </span>
            <span className="text-base text-custom-blue100 pl-[9px] pr-[15px] lgx:text-sm dark:text-white dark:text-opacity-60">
              ETH | USDT
            </span>
            <div className="border-solid border-[1px] border-custom-blue100 border-opacity-[0.4] rounded px-[5px] text-sm text-custom-blue100 dark:text-white dark:text-opacity-60">
              0.3%
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-between lgx:items-start">
          <h1 className="text-sm font-semibold text-custom-gray800 dark:text-white">
            Fiyat Aralığı
          </h1>
          <div className="flex items-center justify-between lgx:flex-col">
            <div>
              <span className="text-sm text-custom-gray800 font-semibold dark:text-white">
                Minimum:
              </span>
              <span className="text-sm text-custom-gray800 dark:text-white dark:text-opacity-60">
                0.00043681 ETH | USDT
              </span>
            </div>
            <ArrowDouble
              size="20"
              fill="#284862"
              className="mx-[20px] lgx:hidden fill-[#284862] dark:fill-[#68B1F7]"
            />
            <div>
              <span className="text-sm text-custom-gray800 font-semibold dark:text-white">
                Maksimum:
              </span>
              <span className="text-sm text-custom-gray800 dark:text-white dark:text-opacity-60">
                0.00043681 ETH | USDT
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[361px] ml-[16px] xlx:w-full xlx:ml-0 xlx:mt-[13px] xlx:flex xlx:items-end lgx:mb-[10px] lgx:mt-0">
        <div className="xlx:w-full xlx:mr-[20px]">
          <h1 className="text-sm text-custom-gray800 font-semibold dark:text-white">
            Arama
          </h1>
          <div className="relative border-solid border-[1px] border-custom-blue400 bg-custom-gray300 border-opacity-[0.54] rounded-lg px-[15px] h-[40px] dark:bg-custom-blue900 dark:border-custom-blue700">
            <Search
              fillOpacity="0.54"
              size="17"
              className="absolute fill-[#002C3E] top-[50%] translate-y-[-50%] dark:fill-[rgba(255,255,255,0.6)]"
            />
            <input
              type="text"
              placeholder="Havuz Veya Belirteç Arayın"
              className="h-full bg-transparent outline-none w-full pl-[35px] text-custom-blue400 text-opacity-[0.54] placeholder-custom-blue400 placeholder-opacity-[0.54] lgx:text-sm dark:text-white/60 dark:placeholder-white/60"
            />
          </div>
        </div>
        {width < 1280 && width > 1024 && (
          <div className="h-[36px] text-xl flex justify-between w-full">
            <div className="h-full w-full mr-[9px] border-solid border-[1px] border-custom-blue400 border-opacity-[0.16] rounded-[8px] flex items-center justify-center">
              <Arrow
                size="12"
                className=" rotate-90 fill-[#002C3E8A] mr-[9px] dark:fill-[#FFFFFF99]"
              />
              <span className="text-base text-custom-blue400 text-opacity-[0.54]">
                Daha Fazla
              </span>
            </div>
            <button className="h-full text-base w-full border-none rounded-[8px] bg-custom-orange text-custom-gray600 flex items-center justify-center">
              <Plus size="12" className="fill-[#F5F5F5] mr-[9px]" />
              Yeni Pozisyon
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPositions;
