import axios from "axios";

export const getSupportedTokens = async (chainId) => {
  let chain;
  if (chainId === 1 || chainId === 4) chain = 1;
  else chain = 56;
  const response = await axios.get(
    `https://api.1inch.exchange/v4.0/${chain}/tokens`
  );

  return response?.data?.tokens;
};
