import { Ethereum } from "src/commons/Icons";

const Request = props => {
  const {currencyOne, currencyTwo} = props;

  return (
    <div className="w-full h-full flex flex-col px-[32px] py-[17px] bg-custom-gray300 border-solid border-[1px] rounded-lg border-custom-gray400 shadow-homeCard dark:bg-custom-blue800 dark:border-custom-blue1300">
      <div className="flex justify-between items-center mb-[13px]">
        <div>
          <h1 className="text-custom-gray1200 text-base font-semibold mb-[6px] dark:text-white">
            Talep Edilmemiş Ücretler
          </h1>
          <span className="text-custom-blue1400 text-2xl font-bold dark:text-white lgx:text-base">
            $0.85
          </span>
        </div>
        <button className="truncate text-base px-[9px] border-none rounded-[6px] bg-custom-orange text-custom-gray600 flex items-center justify-center py-[8px] lgx:text-sm">
          Ücretleri Topla
        </button>
      </div>
      <div className="px-[24px] py-[8px] flex flex-col bg-custom-gray1100 border-solid border-[1px] rounded-lg border-custom-gray400 dark:bg-custom-blue900 dark:border-custom-blue700">
        <div className="flex items-center my-[12px]">
          <p className="text-custom-blue1400 text-lg mr-auto flex items-center dark:text-white/60 lgx:text-base">
            <span className="flex items-center justify-center rounded-full p-[2.1px] bg-custom-blue300 mr-[10px]">
              <Ethereum size="16" />
            </span>
            {currencyOne}
          </p>
          <span className="text-custom-blue1400 text-lg dark:text-white/60 lgx:text-base">
            0.0000458
          </span>
        </div>
        <div className="flex items-center my-[12px]">
          <p className="text-custom-blue1400 text-lg mr-auto flex items-center dark:text-white/60 lgx:text-base">
            <span className="flex items-center justify-center rounded-full p-[2.1px] bg-custom-blue300 mr-[10px]">
              <Ethereum size="16" />
            </span>
            {currencyTwo}
          </p>
          <span className="text-custom-blue1400 text-lg dark:text-white/60 lgx:text-base">
            0.7458
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[14px]">
        <span className="text-sm text-black/50 dark:text-white/60">
          WWTEH olarak toplayın
        </span>
        <div className="flex bg-custom-blue500 rounded p-[2px]">
          <label className="relative cursor-pointer flex items-center justify-center">
            <input
              type="radio"
              name="requestType"
              id="opened"
              className="hidden peer"
            />
            <p className="rounded w-full h-full flex items-center justify-start text-sm text-white py-[4px] px-[10px] peer-checked:bg-custom-blue600 ">
              Açık
            </p>
          </label>
          <label className="relative cursor-pointer flex items-center justify-center">
            <input
              type="radio"
              name="requestType"
              id="closed"
              className="hidden peer"
              defaultChecked
            />
            <p className="rounded w-full h-full flex items-center justify-start text-sm text-white py-[4px] px-[10px] peer-checked:bg-custom-blue600">
              Kapalı
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Request;
