import { Fragment } from "react";
import Navbar from "./Navbar";

function Layout(props) {
  return (
    <Fragment>
      <Navbar />
      <main className="ml-[80px] mb-[30px] xlx:mb-[20px] xlx:ml-[unset]">
        {props.children}
      </main>
    </Fragment>
  );
}

export default Layout;
