import { Metamask, Ledger, Trezor, ThreeDots } from "src/commons/Icons";
import Chart from "react-apexcharts";
import { useWeb3React } from "@web3-react/core";
import { connectorsByName } from "src/hooks/connectorsParams";
import { injected, walletconnect } from "src/hooks/connectors";
import { useEffect, useState } from "react";
import { useValidateTokensAndCallMultiCall } from "src/blockchain/hooks/useValidateTokensAndCallMultiCall";
import { formatEther } from "@ethersproject/units";
import { resetWalletConnector } from "src/hooks/hooks";
import { isEmpty } from "src/utils/method";

const Swap = (props) => {
  const { setActivatingConnector } = props;
  const context = useWeb3React();
  const [tokenList, setTokenList] = useState([]);
  const { connector, library, account, activate, deactivate, active, chainId } =
    context;
  const options = {
    legend: { show: false },
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    stroke: {
      show: false,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 0,
      dashArray: 0,
    },
    plotOptions: {
      pie: {
        startAngle: -185,
        endAngle: 175,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            width: 128,
            height: 128,
          },
        },
      },
    ],
    colors: ["#284862", "#DF6E4E", "#68B2F7"],
  };

  const tokens = useValidateTokensAndCallMultiCall();
  console.log(tokens);
  useEffect(() => {
    const newTokens = tokens?.filter((item) => item.balance > 0);
    setTokenList(newTokens);
  }, [tokens]);
  useEffect(() => {
    if (account !== "" && connector === walletconnect) {
      localStorage.setItem("walletType", "WalletConnect");
    }
  }, [account]);

  function Balance() {
    const { account, library, chainId } = useWeb3React();

    const [balance, setBalance] = useState();
    useEffect(() => {
      if (!!account && !!library) {
        let stale = false;

        library
          .getBalance(account)
          .then((balance) => {
            if (!stale) {
              setBalance(balance);
            }
          })
          .catch(() => {
            if (!stale) {
              setBalance(null);
            }
          });

        return () => {
          stale = true;
          setBalance(undefined);
        };
      }
    }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

    return (
      <div className="lgx:flex lgx:items-center">
        <span className="relative text-base text-custom-blue100 pl-[8px] before:absolute before:left-0 before:bg-custom-blue100 before:w-[2px] before:h-[14px] before:rounded-[2px] before:top-0 before:bottom-0 before:m-auto lgx:text-[11px] lgx:before:h-[11px] dark:text-white">
          ETH
        </span>
        <p className="truncate text-sm text-custom-blue400 text-opacity-[0.54] pl-[8px] lgx:text-[11px] lgx:pl-[4px] dark:text-white/70">
          <span>
            {balance === null
              ? "Error"
              : balance
              ? `${formatEther(balance)}`
              : " ETH"}
          </span>
        </p>
      </div>
    );
  }

  console.log('active: ' , active);
  return (
    <div className="w-full h-full bg-wallet bg-cover grid grid-cols-2 grid-rows-walletWeb gap-y-[42px] px-[25px] py-[17px] relative border-solid border-[1px] border-custom-gray400 rounded-[8px] xlx:gap-[24px] lgx:grid-rows-walletMobile lgx:gap-[12px] lgx:px-[15px] lgx:py-[12px] lgx:grid-cols-walletMobile dark:bg-walletDark dark:border-custom-blue400">
      <div className="col-span-2 flex items-center lgx:order-1 lgx:flex-col lgx:items-start">
        <span className="text-xl font-semibold text-custom-blue100 pr-[19px] dark:text-white">
          Cüzdanım {account?.substring(0, 7)} {chainId}
        </span>
        {!active ? (
          <div className="flex w-full overflow-x-auto overflow-y-hidden scrollhide lgx:mt-[7px]">
            <label
              onClick={() => {
                setActivatingConnector(connectorsByName["Injected"]);
                activate(connectorsByName["Injected"]);
                localStorage.setItem("walletType", "Injected");
              }}
              className="mr-[6px] relative cursor-pointer w-[80px] h-[30px] flex items-center justify-center shadow-walletButton"
            >
              <input
                type="radio"
                name="walletType"
                id="metamask"
                className="hidden peer"
              />
              <div className="rounded-l-[4px] h-full bg-white peer-checked:bg-custom-blue100 flex items-center justify-center pl-[6px] py-[6px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900">
                <Metamask fill="#F5F5F5" size={20} />
              </div>
              <p className="rounded-r-[4px] w-full h-full flex items-center justify-start pr-[6px] py-[6px] bg-white peer-checked:bg-custom-blue100 text-[8px] text-custom-blue400 text-opacity-[0.54] peer-checked:text-custom-gray600 peer-checked:text-opacity-100 pl-[4px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900 dark:text-white">
                METAMASK
              </p>
            </label>
            <label
              onClick={() => {
                setActivatingConnector(connectorsByName["WalletConnect"]);
                activate(
                  connectorsByName["WalletConnect"],
                  undefined,
                  true
                ).catch((error) => {
                  setActivatingConnector(undefined);
                  resetWalletConnector(connectorsByName["WalletConnect"]);
                });
              }}
              className="mr-[6px] relative cursor-pointer w-[100px] h-[30px] flex items-center justify-center shadow-walletButton"
            >
              <div className="rounded-l-[4px] h-full bg-white peer-checked:bg-custom-blue100 flex items-center justify-center pl-[6px] py-[6px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900">
                <Ledger fill="#002C3E" size={20} fillOpacity="0.54" />
              </div>
              <p className="rounded-r-[4px] w-full h-full flex items-center justify-start pr-[6px] py-[6px] bg-white peer-checked:bg-custom-blue100 text-[8px] text-custom-blue400 text-opacity-[0.54] peer-checked:text-custom-gray600 peer-checked:text-opacity-100 pl-[4px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900 dark:text-white">
                WalletConnect
              </p>
            </label>
            <label className="mr-[6px] relative cursor-pointer w-[80px] h-[30px] flex items-center justify-center shadow-walletButton">
              <input
                type="radio"
                name="walletType"
                id="metamask"
                className="hidden peer"
              />
              <div className="rounded-l-[4px] h-full bg-white peer-checked:bg-custom-blue100 flex items-center justify-center pl-[6px] py-[6px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900">
                <Trezor fill="#002C3E" size={20} fillOpacity="0.54" />
              </div>
              <p className="rounded-r-[4px] w-full h-full flex items-center justify-start pr-[6px] py-[6px] bg-white peer-checked:bg-custom-blue100 text-[8px] text-custom-blue400 text-opacity-[0.54] peer-checked:text-custom-gray600 peer-checked:text-opacity-100 pl-[4px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900 dark:text-white">
                TREZOR
              </p>
            </label>
            <label className="mr-[6px] relative cursor-pointer w-[80px] h-[30px] flex items-center justify-center shadow-walletLastButton">
              <input
                type="radio"
                name="walletType"
                id="metamask"
                className="hidden peer"
              />
              <div className="rounded-l-[4px] h-full bg-white peer-checked:bg-custom-blue100 flex items-center justify-center pl-[6px] py-[6px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900">
                <ThreeDots fill="#002C3E" size={7} fillOpacity="0.54" />
              </div>
              <p className="rounded-r-[4px] w-full h-full flex items-center justify-start pr-[6px] py-[6px] bg-white peer-checked:bg-custom-blue100 text-[8px] text-custom-blue400 text-opacity-[0.54] peer-checked:text-custom-gray600 peer-checked:text-opacity-100 pl-[4px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900 dark:text-white">
                OTHERS
              </p>
            </label>
          </div>
        ) : (
          <label
            onClick={() => {
              connector === walletconnect ? connector.close() : deactivate();
              localStorage.setItem("walletType", "");
              injected.deactivate();
              
              setTimeout(() => {
                if (connector === walletconnect) window.location.reload();
              }, 1000);
            }}
            className="mr-[6px] relative cursor-pointer w-[80px] h-[30px] flex items-center justify-center shadow-walletLastButton"
          >
            <input
              type="radio"
              name="walletType"
              id="metamask"
              className="hidden peer"
            />
            <div className="rounded-l-[4px] h-full bg-white peer-checked:bg-custom-blue100 flex items-center justify-center pl-[6px] py-[6px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900">
              <ThreeDots fill="#002C3E" size={7} fillOpacity="0.54" />
            </div>
            <p className="rounded-r-[4px] w-full h-full flex items-center justify-start pr-[6px] py-[6px] bg-white peer-checked:bg-custom-blue100 text-[8px] text-custom-blue400 text-opacity-[0.54] peer-checked:text-custom-gray600 peer-checked:text-opacity-100 pl-[4px] dark:peer-checked:bg-custom-orange dark:bg-custom-blue900 dark:text-white">
              Çıkış
            </p>
          </label>
        )}
      </div>
      <div className="relative lgx:order-3">
        <Chart
          options={options}
          type="donut"
          width="213px"
          height="213px"
          series={[185, 100, 75]}
        />
        {active ?
        <div className="absolute top-[15px] left-[28px] rounded-full lgx:left-[22px] lgx:top-[9px]">
          <div className="w-[150px] h-[150px] bg-white m-[5px] rounded-full flex flex-col items-center justify-center lgx:w-[77px] lgx:h-[77px]">
            <span className="text-l text-custom-blue100 font-semibold lgx:text-xs">
        {!isEmpty(tokenList) ? (tokenList[0]?.balance?.toFixed(4) + ' ' + tokenList[0]?.name) : ''}
            </span>
          </div>
        </div> : 
        <div className="absolute top-[15px] left-[28px] rounded-full lgx:left-[22px] lgx:top-[9px]">
          <div className="w-[150px] h-[150px] bg-white m-[5px] rounded-full flex flex-col items-center justify-center lgx:w-[77px] lgx:h-[77px]">
            <span className="text-xl text-custom-blue100 font-semibold lgx:text-xs">
              ...
            </span>
            <span className="text-xs text-custom-gray500 lgx:text-[9px] lgx:leading-[13px]">
              ...
            </span>
          </div>
        </div> 
        }
      </div>
      <div className="grid grid-flow-row gap-[11px] h-full overflow-x-hidden overflow-y-auto scrollhide xlx:pt-[18px] lgx:pt-[5px] lgx:order-2 lgx:gap-[3px]">
        {active ? <Balance /> : null}
        {active ? tokenList?.map((item, index) => {
          return (
            <div key={index.toString()} className="lgx:flex lgx:items-center">
              <span className="relative text-base text-custom-blue100 pl-[8px] before:absolute before:left-0 before:bg-custom-blue100 before:w-[2px] before:h-[14px] before:rounded-[2px] before:top-0 before:bottom-0 before:m-auto lgx:text-[11px] lgx:before:h-[11px] dark:text-white">
                {item.name}
              </span>
              <p className="truncate text-sm text-custom-blue400 text-opacity-[0.54] pl-[8px] lgx:text-[11px] lgx:pl-[4px] dark:text-white/70">
                <span>
                  {item.balance} {item.symbol}
                </span>
              </p>
            </div>
          );
        }) : 
        
        <div  className="lgx:flex lgx:items-center">
                Lütfen cüzdanınızı bağlayınız.
              <p className="truncate text-sm text-custom-blue400 text-opacity-[0.54] pl-[8px] lgx:text-[11px] lgx:pl-[4px] dark:text-white/70">
                <span>
                </span>
              </p>
            </div>
        }
      </div>
      {/* <Eye
        size="20"
        fillOpacity="0.38"
        className="fill-[#002C3E] absolute bottom-[12px] right-[12px]"
      /> */}
    </div>
  );
};

export default Swap;
