import { useState } from "react";
import { useLocation } from "react-router";
import {
  Wallet,
  Chart as ChartComp,
  Trade,
  RecentPoolTransactions,
} from "src/components/view/Cards";

const LiquidityPoolSigned = props => {
  const location = useLocation()
  const {currencyOne, currencyTwo} = location.state;
  return (
    <div className="mr-[30px] grid grid-cols-liquidityPoolLoggedWeb justify-center gap-4 grid-rows-liquidityPoolLoggedWeb xlx:grid-rows-liquidityPoolLoggedTablet xlx:mx-[20px] xlx:grid-cols-1 lgx:grid-rows-liquidityPoolLoggedMobile lgx:mx-[13px]">
      <div className="order-1 xlx:order-3">
        <Trade currencyOne={currencyOne} currencyTwo={currencyTwo} />
      </div>
      <div className="order-2 xlx:order-1">
        <Wallet />
      </div>
      <div className="order-3 border-custom-gray400 border-solid border-[1px] rounded dark:border-custom-blue700">
        <ChartComp currencyOne={currencyOne} currencyTwo={currencyTwo} />
      </div>
      <div className="order-4 border-custom-gray400 border-solid border-[1px] rounded dark:border-custom-blue700">
        <ChartComp currencyOne={currencyOne} currencyTwo={currencyTwo} type="candlestick" />
      </div>
      <div className="order-5 col-span-2 xlx:col-span-1">
        <RecentPoolTransactions />
      </div>
    </div>
  );
};

export default LiquidityPoolSigned;
