import {
  Wallet,
  PoolOverview,
  MyPositions,
  PoolsTable,
  Download,
} from "src/components/view/Cards";
import UpperPools from "src/components/view/Cards/UpperPools";

const LiquidityPool = () => {
  return (
    <div className="mr-[30px] grid grid-cols-liquidityPoolWeb justify-center gap-4 grid-rows-liquidityPoolWeb xlx:grid-rows-liquidityPoolTablet xlx:mx-[20px] xlx:grid-cols-liquidityPoolTablet lgx:grid-cols-1 lgx:grid-rows-liquidityPoolMobile lgx:mx-[13px]">
      <div className="order-1 xlx:order-4 lgx:order-2">
        <PoolOverview />
      </div>
      <div className="order-2 xlx:order-5 lgx:order-3">
        <UpperPools />
      </div>
      <div className="order-3 xlx:order-1">
        <Wallet />
      </div>
      <div className="order-4 col-span-3 xlx:order-3 xlx:col-span-2 lgx:col-span-1 lgx:order-4">
        <MyPositions />
      </div>
      <div className="order-5 col-span-3 xlx:order-6 xlx:col-span-2 lgx:col-span-1 lgx:order-5">
        <PoolsTable />
      </div>
      <div className="hidden xlx:block xlx:order-2 lgx:hidden">
        <Download />
      </div>
    </div>
  );
};

export default LiquidityPool;
