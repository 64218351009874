import React from "react";
import {
} from "../actions/auth";


const initialState = {
  currentUser: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
