import * as React from "react";

const Star = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.13349 13C2.98011 13 2.82779 12.9536 2.6978 12.8626C2.45565 12.6925 2.34254 12.4002 2.40844 12.1182L3.3091 8.27396L0.254639 5.67843C0.0301158 5.48851 -0.0556559 5.18775 0.0365247 4.91254C0.128705 4.63786 0.379505 4.44339 0.676448 4.41679L4.71787 4.06127L6.3157 0.438108C6.43352 0.171911 6.70183 0 6.99995 0C7.29807 0 7.56639 0.171911 7.6842 0.437487L9.28204 4.06127L13.3228 4.41679C13.6204 4.44277 13.8712 4.63786 13.9634 4.91254C14.0556 5.18723 13.9703 5.48851 13.7458 5.67843L10.6913 8.27344L11.592 12.1176C11.658 12.4002 11.5448 12.6925 11.3027 12.862C11.0612 13.0316 10.7392 13.0446 10.4849 12.8965L6.99995 10.8786L3.51503 12.8976C3.39722 12.9654 3.26594 13 3.13349 13ZM6.99995 10.0093C7.1324 10.0093 7.26357 10.0438 7.38149 10.1116L10.6704 12.0176L9.82038 8.38926C9.75971 8.13103 9.85018 7.86142 10.0561 7.68671L12.9402 5.23587L9.12449 4.90012C8.84976 4.8758 8.61349 4.70845 8.50614 4.46315L6.99995 1.04399L5.49195 4.46367C5.38578 4.70731 5.1495 4.87466 4.87542 4.89899L1.05916 5.23473L3.94315 7.68557C4.14972 7.8608 4.24009 8.12989 4.17888 8.38874L3.3295 12.017L6.61841 10.1116C6.73623 10.0438 6.8675 10.0093 6.99995 10.0093ZM4.687 4.13134C4.687 4.13134 4.687 4.13196 4.68636 4.13248L4.687 4.13134ZM9.31173 4.12968L9.31237 4.13082C9.31237 4.1302 9.31237 4.1302 9.31173 4.12968Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Star;
