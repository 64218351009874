//REDUX
import {createStore , combineReducers,applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import authReducer from './reducers/auth';
import {composeWithDevTools} from 'redux-devtools-extension';
import homeReducer from './reducers/homeReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
});


// export const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(ReduxThunk))); //RemoveThisWhenProductionOccurs
export const store = createStore(rootReducer,applyMiddleware(ReduxThunk)); //RemoveThisWhenProductionOccurs

export default {
    store
};