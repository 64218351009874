import { ArrowDouble } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";
import SwapDropdown from "src/components/ui/dropdown/SwapDropdown";
import axios from "axios";
import Web3 from "web3";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useActiveWeb3React } from "src/blockchain/hooks/web3";
import { isEmpty } from "src/utils/method";
import { getSupportedTokens } from "src/hooks/swapUtils";
const Swap = () => {
  const { width } = useWindowSize();
  const [fromAddress, setFromAddress] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [toTokenAmount, setToTokenAmount] = useState(0);
  const [web3jsInstance, setWeb3jsInstance] = useState(null);
  const [fromTokenAmount, setFromTokenAmount] = useState(0);
  const context = useActiveWeb3React();
  const { chainId, account } = context;
  const [tokens, setTokens] = useState([]);
  const { active, library, connector, activate, deactivate } =
    useWeb3React();
  useEffect(() => {
    connector?.getProvider().then((provider) => {
      const instance = new Web3(provider);
      setWeb3jsInstance(instance);
    });
  }, [active, connector]);
  const getTokens = async () => {
    const token = await getSupportedTokens(chainId);
    setTokens(token);
  };
  useEffect(() => {
    getTokens();
  }, [chainId]);



  useEffect(()=>{
    if(tokens === undefined || tokens === "") return;
    setFromAddress(!isEmpty(tokens["0xdac17f958d2ee523a2206206994597c13d831ec7"]) ? tokens["0xdac17f958d2ee523a2206206994597c13d831ec7"] : '');
    setToAddress(!isEmpty(tokens["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"]) ? tokens["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"] : '');
  },[tokens])



  async function approve(tokenAddress, tokenAmount) {
    try {
      const response = await axios.get(
        `https://api.1inch.exchange/v4.0/1/approve/calldata?tokenAddress=${tokenAddress}&amount=${tokenAmount}`
      );
      if (response.data) {
        const data = response.data;
        data.gas = 1000000;
        data.from = account;
        const tx = await web3jsInstance.eth.sendTransaction(data);
        if (tx.status) {
          console.log("Approval Successul! :)");
        } else {
          console.log("Approval unsuccesful :(");
          console.log(tx);
        }
      }
    } catch (err) {
      console.log("could not approve token");
      console.log(err);
    }
  }

  async function swapper(fromTokenAddress, toTokenAddress, fromTokenAmount) {
    try {
      const response = await axios.get(
        `https://api.1inch.exchange/v3.0/1/swap?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${fromTokenAmount}&fromAddress=${account}&slippage=0.1&disableEstimate=true`
      );
      if (response.data) {
        const data = response.data;
        data.tx.gas = 1000000;
        const tx = await web3jsInstance.eth.sendTransaction(data.tx);
        if (tx.status) {
          alert("Swap Successfull! :)");
        }
      }
    } catch (err) {
      console.log("swapper encountered an error below");
      console.log(JSON.stringify(err));
    } finally {
      // alert("swap tamamlandı");
    }
  }
  async function getEstimatedToken() {
    try {
      let deneme = 0;
      if (Number(fromTokenAmount) <= 0 || fromTokenAmount === "") {
        deneme = 0;
      } else {
        deneme = (fromTokenAmount * 10 ** 18).toLocaleString("fullwide", {
          useGrouping: false,
        });
      }
      console.log("deneme", deneme);
      const response = await axios.get(
        `https://api.1inch.exchange/v4.0/1/quote?fromTokenAddress=${fromAddress.address}&toTokenAddress=${toAddress.address}&amount=${deneme}`
      );
      if (response.data) {
        console.log(response.data);
        setToTokenAmount(
          Number(
            response.data?.toTokenAmount / 10 ** response.data.toToken.decimals
          ).toFixed(18)
        );
      }
    } catch (err) {
      setToTokenAmount(0);
      console.log("swapper encountered an error below");
      console.log(JSON.stringify(err));
    }
  }

  useEffect(() => {
    if (fromAddress.address !== "" && toAddress.address) {
      getEstimatedToken();
      console.log(
        (fromTokenAmount * 10 ** 18).toLocaleString("fullwide", {
          useGrouping: false,
        })
      );
    }
  }, [fromAddress?.address, toAddress?.address, fromTokenAmount]);
  console.log(toTokenAmount);
  return (
    <div className="w-full h-full border-solid border-[1px] border-custom-gray400 rounded-[8px] flex flex-col items-center bg-custom-gray300 px-[16px] py-[11px] dark:bg-custom-blue800 dark:border-custom-blue700">
      <div className="w-full mb-[33px] flex items-center justify-center mt-[36px] px-[16px] xlx:mt-[8px] lgx:flex-col lgx:px-[unset] lgx:mt-[unset] lgx:mb-[16px]">
        <div className="w-[45%] flex flex-col items-start lgx:w-full">
          <span className="text-custom-blue400 text-opacity-[0.6] text-base mb-[6px] font-semibold lgx:text-sm lgx:mb-[2px] dark:text-white">
            Gönder
          </span>
          <div className="relative w-full border-solid border-[0.8px] border-custom-blue400 border-opacity-[0.20] rounded-[8px] px-[20px] py-[9px] flex justify-between items-center bg-white lgx:px-[12px] dark:bg-custom-blue900 dark:border-custom-blue1000">
            <input
              type="text"
              className="w-full h-full text-custom-blue400 text-opacity-[0.54] text-[18px] bg-transparent outline-none lgx:text-[14px] lgx:text-custom-blue100 dark:text-white"
              defaultValue={0.0}
              onChange={(e) => {
                setFromTokenAmount(e.currentTarget.value);
              }}
            />
            <SwapDropdown
              width="78px"
              setAddress={setFromAddress}
              tokens={tokens}
              seletectedAddress={fromAddress}
            />
          </div>
        </div>
        <span>
          <ArrowDouble className="fill-[rgba(0,44,62,0.54)] w-[25px] mx-[35px] mt-[30px] lgx:w-[14px] lgx:mt-[8px] lgx:mb-[-16px] lgx:mx-[unset] lgx:rotate-90 lgx:fill-[#68B2F7]" />
        </span>
        <div className="w-[45%] flex flex-col items-start lgx:w-full">
          <span className="text-custom-blue400 text-opacity-[0.6] text-base mb-[6px] font-semibold lgx:text-sm lgx:mb-[2px] dark:text-white">
            Al
          </span>
          <div className="relative w-full border-solid border-[0.8px] border-custom-blue400 border-opacity-[0.20] rounded-[8px] px-[20px] py-[9px] flex justify-between items-center bg-white lgx:px-[12px] dark:bg-custom-blue900 dark:border-custom-blue1000">
            <input
              type="text"
              className="w-full h-full text-custom-blue400 text-opacity-[0.54] text-[18px] bg-transparent outline-none lgx:text-[14px] lgx:text-custom-blue100 dark:text-white"
              value={toTokenAmount}
            />
            <SwapDropdown
              width="78px"
              setAddress={setToAddress}
              tokens={tokens}
              seletectedAddress={toAddress}
            />
          </div>
        </div>
      </div>
      <button
        onClick={() =>
          swapper(fromAddress.address, toAddress.address, 10000000000000000)
        }
        className="flex justify-center text-custom-gray600 text-xl py-[9px] mb-[62px] shadow-button text-center bg-custom-orange rounded-[8px] w-[318px] xlx:mb-[12px] xlx:w-[342px] lgx:w-full lgx:text-base lgx:py-[4px] lgx:rounded-[4px]"
      >
        Swap | Takas
      </button>
      <div className="w-full flex justify-between">
        <span className="text-sm text-custom-gray500 lgx:text-[10px] dark:text-white dark:text-opacity-70">
          Estimated Cost {width < 1024 && <br />}~$2.88
        </span>
        <span className="text-sm text-custom-gray500 xlx:mr-auto xlx:ml-[30px] lgx:m-[unset] lgx:text-[10px] lgx:text-center dark:text-white dark:text-opacity-70">
          Price Impact {width < 1024 && <br />}0.44%
        </span>
        <span className="text-sm text-custom-gray500 lgx:text-[10px] lgx:text-right dark:text-white dark:text-opacity-70">
          Minimum Received {width < 1024 && <br />}1819.4418 USDT
        </span>
      </div>
    </div>
  );
};

export default Swap;
