const CurrentPrice = props => {
  const {currencyOne, currencyTwo} = props;

  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-custom-gray300 border-solid border-[1px] rounded-lg border-custom-gray400 shadow-homeCard dark:bg-custom-blue800 dark:border-custom-blue1300">
      <span className="text-custom-blue400 text-lg mb-[4px] dark:text-white/80 lgx:text-sm">
        Güncel Fiyat
      </span>
      <h1 className="text-custom-blue400 text-2xl mb-[4px] text-opacity-[0.54] dark:text-white lgx:text-base">
        0.000434949
      </h1>
      <span className="text-custom-blue400 text-base text-opacity-[0.54] dark:text-white/60 lgx:text-sm">
        {currencyOne} | {currencyTwo}
      </span>
    </div>
  );
};

export default CurrentPrice;
