import { Link } from "react-router-dom";
import { Ethereum } from "src/commons/Icons";

const PoolsTableRows = props => {
  const {_key, item} = props;

  return (
    <Link to = {{
                pathname: '/likiditeHavuzuDetay',
                state: {currencyOne: item.first, currencyTwo: item.second}
           }}>
    <div className="grid grid-cols-poolsTable  py-[10px] justify-items-start items-center border-t-[1px] border-solid border-custom-blue400 border-opacity-[0.2] lgx:py-[0] dark:border-white/30">
      <span className="text-xl text-custom-blue400 text-opacity-[0.54] truncate w-full dark:text-white/60 lgx:text-[10px]">
        {_key + 1}
      </span>
      <div className="flex items-center w-full">
        <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[3px] dark:bg-custom-blue500 lgx:hidden">
          <Ethereum size="15" />
        </span>
        <span className="bg-custom-blue100 rounded-full p-[2.7px] mr-[11px] dark:bg-custom-blue500 lgx:hidden">
          <Ethereum size="15" />
        </span>
        <span className="text-base text-custom-blue400 text-opacity-[0.54] lgx:text-[10px] dark:text-white/60">
          {item.first} | {item.second}
        </span>
      </div>
      <span className="text-base text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60 ">
        0.3%
      </span>
      <span className="text-base text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        $914.29M
      </span>
      <span className="text-base text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        $914.29M
      </span>
      <span className="text-base text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        $273.26M
      </span>
      <span className="text-base text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        $100.69M
      </span>
    </div>
    </Link>
  );
};

export default PoolsTableRows;
