import * as React from "react";

const Metamask = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.0088 1L12.1956 6.93761L13.6485 3.43838L20.0088 1Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99025 1L9.73388 6.99303L8.35054 3.43838L1.99025 1Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1977 14.7671L15.1188 18.0288L19.5702 19.2876L20.8454 14.8383L17.1977 14.7671Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.16273 14.8383L2.43016 19.2876L6.87386 18.0288L4.80271 14.7671L1.16273 14.8383Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.63557 9.25714L5.39906 11.173L9.80413 11.3789L9.65729 6.51001L6.63557 9.25714Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3655 9.25714L12.2974 6.45459L12.1969 11.3788L16.602 11.173L15.3655 9.25714Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.8727 18.0297L9.53892 16.7076L7.24365 14.8708L6.8727 18.0297Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4616 16.7076L15.1201 18.0297L14.7568 14.8708L12.4616 16.7076Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1193 18.0287L12.4608 16.7065L12.6772 18.4799L12.654 19.232L15.1193 18.0287Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.87402 18.0289L9.34704 19.2323L9.33159 18.4802L9.54025 16.7068L6.87402 18.0289Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3919 13.6986L7.18164 13.0336L8.74273 12.2974L9.3919 13.6986Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6055 13.6986L13.2546 12.2974L14.8235 13.0336L12.6055 13.6986Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.86998 18.0283L7.25639 14.7666L4.79883 14.8379L6.86998 18.0283Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7373 14.7666L15.116 18.0283L17.1949 14.8379L14.7373 14.7666Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6022 11.1731L12.1971 11.3789L12.6067 13.6986L13.2559 12.2973L14.8247 13.0335L16.6022 11.1731Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1782 13.0335L8.7393 12.2973L9.38846 13.6986L9.79806 11.3789L5.39299 11.1731L7.1782 13.0335Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.39648 11.1738L7.24352 14.871L7.18169 13.0343L5.39648 11.1738Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8268 13.0331L14.7573 14.8698L16.6043 11.1726L14.8268 13.0331Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.80315 11.3779L9.39355 13.6976L9.91134 16.4368L10.0273 12.8267L9.80315 11.3779Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1969 11.3779L11.9805 12.8188L12.0887 16.4368L12.6065 13.6976L12.1969 11.3779Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6076 13.6982L12.0898 16.4374L12.4608 16.7066L14.7561 14.8699L14.8256 13.0332L12.6076 13.6982Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.18164 13.0332L7.24347 14.8699L9.53873 16.7066L9.90968 16.4374L9.3919 13.6982L7.18164 13.0332Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6547 19.2322L12.6779 18.4801L12.477 18.3059H9.52479L9.33159 18.4801L9.34704 19.2322L6.87402 18.0288L7.73958 18.7572L9.49388 20.0001H12.5001L14.2622 18.7572L15.12 18.0288L12.6547 19.2322Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.46 16.7072L12.089 16.438H9.90969L9.53874 16.7072L9.33008 18.4805L9.52328 18.3064H12.4754L12.6764 18.4805L12.46 16.7072Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3435 7.32553L21.0004 4.04797L20.0112 1L12.4608 6.73969L15.3666 9.25724L19.4703 10.4843L20.3745 9.39974L19.9803 9.10682L20.6063 8.52097L20.1272 8.14097L20.7531 7.65012L20.3435 7.32553Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 4.04797L1.66462 7.32553L1.23957 7.65012L1.87329 8.14097L1.39414 8.52097L2.02012 9.10682L1.62598 9.39974L2.53018 10.4843L6.63385 9.25724L9.53964 6.73969L1.98921 1L1 4.04797Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4701 10.4847L15.3664 9.25757L16.6029 11.1734L14.7559 14.8706L17.198 14.8389H20.8457L19.4701 10.4847Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.63269 9.25757L2.52902 10.4847L1.16113 14.8389H4.80111L7.24321 14.8706L5.39618 11.1734L6.63269 9.25757Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1982 11.3798L12.461 6.74052L13.6511 3.43921H8.34961L9.53975 6.74052L9.80251 11.3798L9.90297 12.8365L9.9107 16.4386H12.0901L12.0978 12.8365L12.1982 11.3798Z"
      fill={fill}
      fillOpacity={fillOpacity}
      stroke="#284862"
      strokeWidth="0.412631"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Metamask;
