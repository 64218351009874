import ListPriceRow from "src/components/ui/row/ListPriceRow";
import { CURRENCIES } from "src/data/currencyData";


const ListPrice = props => {
  const {setCurrencyOne} = props;
  return (
    <div className="bg-custom-gray300 rounded-[8px] lgx:bg-white dark:bg-custom-blue800">
      <div className="grid grid-flow-col overflow-x-auto overflow-y-hidden scrollhide px-[17px] py-[18px]  xlx:w-min xlx:pt-[11px] xlx:pb-[5.57px] xlx:px-[10px] lgx:w-full lgx:py-[13px] lgx:px-[11px]">
        <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="all"
            className="hidden peer"
            defaultChecked
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Hepsi
          </span>
        </label>
        {/* <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="popular"
            className="hidden peer"
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Popular
          </span>
        </label> */}
        <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="fav"
            className="hidden peer"
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Favori
          </span>
        </label>
        <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="sportimec"
            className="hidden peer"
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Sportimec
          </span>
        </label>
        {/* <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="estetimec"
            className="hidden peer"
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Estetimec
          </span>
        </label>
        <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="startimec"
            className="hidden peer"
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Startimec
          </span>
        </label> */}

        <label className="relative cursor-pointer w-min flex items-center justify-center rounded-[4px] mt-[-1px] ml-[-1px] overflow-hidden">
          <input
            type="radio"
            name="priceListType"
            id="startimec"
            className="hidden peer"
          />
          <span className="w-full px-[15px] py-[3px] text-custom-blue400 text-opacity-[0.54] peer-checked:bg-custom-blue100 peer-checked:text-white peer-checked:text-opacity-100 flex items-center justify-center xlx:text-sm lgx:py-[2px] lgx:px-[8.5px] lgx:text-[10px] dark:text-white dark:peer-checked:bg-custom-orange">
            Fanimec
          </span>
        </label> 

      </div>
      <div className="grid grid-cols-4 gap-[60px] py-[6px] px-[48px] justify-items-start truncate bg-white lgx:py-[3px] dark:bg-custom-blue800">
        <span className="font-semibold text-xs text-custom-blue100 lgx:text-[10px] dark:text-white">
          Token Adı
        </span>
        <span className="font-semibold text-xs text-custom-blue100 lgx:text-[10px] dark:text-white">
          Fiyat(USD)
        </span>
        <span className="font-semibold text-xs text-custom-blue100 lgx:text-[10px] dark:text-white">
          Hacim
        </span>
        <span className="font-semibold text-xs text-custom-blue100 justify-self-center lgx:text-[10px] dark:text-white">
          % Değişim
        </span>
      </div>
      <div className="h-[286px] overflow-y-auto overflow-x-hidden scrollhide xlx:h-[104px] lgx:h-[184px]">
        {CURRENCIES.map((item, index) => (
          <ListPriceRow key={index} _key={index} item={item} setCurrencyOne={setCurrencyOne} />
        ))}
      </div>
    </div>
  );
};

export default ListPrice;
