import * as React from "react";

const Search = ({ size, fill, fillOpacity, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.4483 15.38L14.2382 12.1794C15.2739 10.8599 15.8359 9.23054 15.8338 7.55312C15.8338 6.05926 15.3908 4.59894 14.5609 3.35684C13.7309 2.11473 12.5513 1.14663 11.1711 0.57495C9.79097 0.00327262 8.27228 -0.146304 6.80712 0.145135C5.34196 0.436573 3.99612 1.15594 2.9398 2.21226C1.88348 3.26858 1.16411 4.61442 0.872674 6.07958C0.581235 7.54475 0.730812 9.06343 1.30249 10.4436C1.87417 11.8237 2.84227 13.0034 4.08437 13.8333C5.32648 14.6633 6.7868 15.1062 8.28066 15.1062C9.95808 15.1083 11.5875 14.5464 12.9069 13.5106L16.1076 16.7207C16.1954 16.8092 16.2998 16.8795 16.4148 16.9274C16.5299 16.9753 16.6533 17 16.7779 17C16.9026 17 17.026 16.9753 17.141 16.9274C17.2561 16.8795 17.3605 16.8092 17.4483 16.7207C17.5368 16.633 17.607 16.5285 17.6549 16.4135C17.7029 16.2984 17.7275 16.175 17.7275 16.0504C17.7275 15.9257 17.7029 15.8023 17.6549 15.6873C17.607 15.5722 17.5368 15.4678 17.4483 15.38ZM2.61582 7.55312C2.61582 6.43272 2.94806 5.33749 3.57052 4.40591C4.19298 3.47433 5.07771 2.74825 6.11282 2.31949C7.14794 1.89074 8.28695 1.77855 9.38582 1.99713C10.4847 2.21571 11.4941 2.75523 12.2863 3.54748C13.0786 4.33972 13.6181 5.3491 13.8367 6.44797C14.0552 7.54684 13.9431 8.68585 13.5143 9.72097C13.0855 10.7561 12.3595 11.6408 11.4279 12.2633C10.4963 12.8857 9.40106 13.218 8.28066 13.218C6.77825 13.218 5.33738 12.6211 4.27502 11.5588C3.21265 10.4964 2.61582 9.05553 2.61582 7.55312Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Search;
