import * as React from "react";

const Ethereum = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M5.99982 0V6.65388L11.9972 9.16689L5.99982 0Z" fill="#F5F5F5" />
    <path d="M5.99938 0L0.00115967 9.16689L5.99938 6.65388V0Z" fill="white" />
    <path
      d="M5.99982 13.4788V18L12.0012 10.2141L5.99982 13.4788Z"
      fill="#F5F5F5"
    />
    <path d="M5.9989 18V13.478L0.000671387 10.2141L5.9989 18Z" fill="white" />
    <path
      d="M5.99982 12.4322L11.9972 9.16679L5.99982 6.65527V12.4322Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M0.000671387 9.16679L5.9989 12.4322V6.65527L0.000671387 9.16679Z"
      fill="#F5F5F5"
    />
  </svg>
);

export default Ethereum;
