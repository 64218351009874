import { Link } from "react-router-dom";
import { Remove } from "src/commons/Icons";

const RecentPoolTransactionsRow = props => {
  const {item} = props;
  return (
    <Link to = {{
      pathname: '/likiditeHavuzuDetay2',
      state: {currencyOne: item.first, currencyTwo: item.second}
    }}>
    <div className="grid grid-flow-col auto-cols-[minmax(120px,180px)]  py-[5px] justify-items-start items-center lgx:py-[0]">
      <div className="flex items-center w-full">
        <span>
          <Remove size="14" className="mr-[12px]" />
        </span>
        <span className="text-xs text-custom-blue400 text-opacity-[0.54] truncate lgx:text-[10px] dark:text-white/60">
          Swap {item.first} for {item.second}
        </span>
      </div>
      <span className="text-xs text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60 ">
        $124.21 k
      </span>
      <span className="text-xs text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        124.01K {item.second}
      </span>
      <span className="text-xs text-custom-blue400 text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        54.53 {item.first}
      </span>
      <span className="text-xs text-custom-blue500 truncate w-full lgx:text-[10px] dark:text-white/60">
        0x4221...F886
      </span>
      <span className="text-xs text-custom-blue400 text-right text-opacity-[0.54] truncate w-full lgx:text-[10px] dark:text-white/60">
        1 saat önce
      </span>
    </div>
    </Link>
  );
};

export default RecentPoolTransactionsRow;
