import "./styles/index.css";
import { Layout } from "src/components/composite";
import Home from "src/screens/Home";
import Transfer from "./screens/Transfer";
import LiquidityPool from "./screens/LiquidityPool";
import LiquidityPoolSigned from "./screens/LiquidityPoolSigned";
import LiquidityPoolSigned2 from "./screens/LiquidityPoolSigned2";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useEagerConnect, useInactiveListener } from "./hooks/hooks";
import { useEffect, useState } from "react";
function App() {
  const context = useWeb3React();
  const { connector } = context;
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Home setActivatingConnector={setActivatingConnector} />
          </Route>
          <Route path="/limitliEmir">
            <Home />
          </Route>
          <Route path="/transfer">
            <Transfer />
          </Route>
          <Route path="/likiditeHavuzu">
            <LiquidityPool />
          </Route>
          <Route path="/likiditeHavuzuDetay">
            <LiquidityPoolSigned />
          </Route>
          <Route path="/likiditeHavuzuDetay2">
            <LiquidityPoolSigned2 />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
