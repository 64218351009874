import * as React from "react";

const Notification = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.0798 2.98179C19.0798 1.81862 18.1336 0.872382 16.9705 0.872349C15.8074 0.872316 14.8611 1.81858 14.8611 2.98169C14.8611 3.32806 14.9456 3.6548 15.0941 3.94343C12.4 4.70154 10.3737 7.03891 10.0859 9.91832L9.30036 19.0125C9.217 19.0241 8.9406 19.1488 8.8748 19.1698C7.40935 19.6355 6.4248 20.9821 6.4248 22.5208C6.42483 23.6867 7.36818 24.6302 8.53417 24.6302L13.5262 24.6302C13.8474 26.2207 15.2554 27.4427 16.9716 27.4426C18.6865 27.4426 20.0956 26.2219 20.4171 24.6302L25.4091 24.6302C26.5721 24.6302 27.5184 23.684 27.5184 22.5209C27.5185 20.9821 26.5339 19.6355 25.0683 19.1698C25.0027 19.1489 24.7262 19.0241 24.6429 19.0125L23.8548 9.91507C23.5651 7.01816 21.5198 4.6956 18.8469 3.9435C18.9954 3.6549 19.0799 3.32812 19.0798 2.98179ZM16.9715 26.0363C16.0416 26.0363 15.267 25.4388 14.9818 24.6301H18.9612C18.6772 25.4358 17.9047 26.0363 16.9715 26.0363ZM26.1121 22.5207C26.1121 22.9085 25.7967 23.2238 25.409 23.2238L8.53414 23.2239C8.14544 23.2239 7.83102 22.9095 7.83102 22.5208C7.83102 21.9451 8.06055 21.4143 8.44643 21.0284C8.67948 20.7954 8.96967 20.6152 9.30059 20.51C9.38915 20.4819 9.94487 20.4115 9.94487 20.4115C9.94533 20.4115 9.94573 20.4115 9.94619 20.4115L23.9969 20.4114C23.9973 20.4114 23.9978 20.4114 23.9983 20.4114C23.9983 20.4114 24.554 20.4818 24.6425 20.5099C25.5215 20.7892 26.1121 21.5973 26.1121 22.5207ZM22.4552 10.0516L23.2294 19.0051L10.7136 19.0051L11.4855 10.0549C11.7685 7.22503 14.1265 5.09109 16.9705 5.09106C19.8145 5.09109 22.1725 7.22509 22.4552 10.0516ZM16.9705 3.68483C16.5818 3.6848 16.2674 3.37038 16.2674 2.98172C16.2674 2.59306 16.5818 2.27861 16.9705 2.27861C17.3592 2.27861 17.6735 2.59306 17.6736 2.98172C17.6736 3.37032 17.3591 3.6848 16.9705 3.68483Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M14.9992 7.71346C14.6561 7.71343 14.3778 7.99172 14.3778 8.3349C14.3778 8.67815 14.656 8.95638 14.9993 8.95638C16.2305 8.95641 17.2512 9.88023 17.3736 11.1054L17.5343 12.7119C17.5685 13.0534 17.873 13.3025 18.2145 13.2684C18.5561 13.2342 18.8052 12.9297 18.7711 12.5881L18.6103 10.9818C18.4243 9.11857 16.8719 7.71349 14.9992 7.71346Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Notification;
