import * as React from "react";

const Ledger = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.9175 0H7.23423V11.7178H18.9521V3.03454C18.9521 1.35319 17.5989 0 15.9175 0ZM4.52785 0H3.03454C1.35319 0 0 1.35319 0 3.03454V4.52785H4.52785V0ZM0 7.23423H4.52785V11.7621H0V7.23423ZM14.4722 18.9521H15.9655C17.6468 18.9521 19 17.5989 19 15.9175V14.4722H14.4722V18.9521ZM7.23423 14.4722H11.7621V19H7.23423V14.4722ZM0 14.4722V15.9655C0 17.6468 1.35319 19 3.03454 19H4.52785V14.4722H0Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Ledger;
