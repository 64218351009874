import * as React from "react";

const Ava = ({ size, fill, fillOpacity }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.58985 7.32934H1.14117C0.797688 7.32934 0.625944 7.32934 0.523089 7.26255C0.411926 7.19039 0.341809 7.06927 0.334371 6.93664C0.327492 6.81391 0.412573 6.66427 0.58274 6.365L3.75457 0.786135C3.92719 0.482487 4.01352 0.330659 4.12334 0.274146C4.24199 0.21308 4.38269 0.212973 4.50145 0.273868C4.61133 0.330219 4.69787 0.481919 4.87094 0.785317L5.60249 2.06765C5.72837 2.28827 5.79128 2.39858 5.81897 2.51497C5.84907 2.64166 5.84911 2.77366 5.81911 2.90036C5.79156 3.01677 5.72869 3.12716 5.60304 3.34789L3.70632 6.6792C3.57883 6.90318 3.51507 7.01514 3.42772 7.09886C3.33263 7.18997 3.21733 7.25714 3.0913 7.29477C2.9755 7.32934 2.84695 7.32934 2.58985 7.32934Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M7.75766 7.32954H6.07808C5.73147 7.32954 5.55817 7.32954 5.45499 7.26219C5.34353 7.18942 5.27359 7.06739 5.26703 6.93416C5.26088 6.81082 5.34811 6.66059 5.52253 6.36015L6.36095 4.91602C6.53319 4.61938 6.61931 4.47103 6.72836 4.41547C6.8462 4.35542 6.98552 4.35533 7.10345 4.41519C7.21259 4.47062 7.2989 4.61882 7.47156 4.91523L8.31271 6.35932C8.48782 6.65998 8.57537 6.81032 8.56941 6.9337C8.56294 7.06711 8.49304 7.18919 8.38149 7.26209C8.27832 7.32954 8.10478 7.32954 7.75766 7.32954Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M15.0309 7.32934H13.5823C13.2388 7.32934 13.0671 7.32934 12.9642 7.26255C12.853 7.19039 12.7829 7.06927 12.7755 6.93664C12.7686 6.81391 12.8537 6.66427 13.0238 6.365L16.1957 0.786135C16.3683 0.482487 16.4546 0.330659 16.5644 0.274146C16.6831 0.21308 16.8238 0.212973 16.9425 0.273868C17.0525 0.330219 17.139 0.481919 17.3121 0.785317L18.0436 2.06765C18.1694 2.28827 18.2324 2.39858 18.2601 2.51497C18.2902 2.64166 18.2902 2.77366 18.2602 2.90036C18.2327 3.01677 18.1698 3.12716 18.0442 3.34789L16.1474 6.6792C16.0199 6.90318 15.9562 7.01514 15.8689 7.09886C15.7738 7.18997 15.6584 7.25714 15.5324 7.29477C15.4166 7.32934 15.2881 7.32934 15.0309 7.32934Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M20.2005 7.32954H18.5209C18.1743 7.32954 18.001 7.32954 17.8978 7.26219C17.7863 7.18942 17.7164 7.06739 17.7098 6.93416C17.7037 6.81082 17.7909 6.66059 17.9654 6.36015L18.8038 4.91602C18.976 4.61938 19.0621 4.47103 19.1712 4.41547C19.289 4.35542 19.4283 4.35533 19.5463 4.41519C19.6554 4.47062 19.7417 4.61882 19.9144 4.91523L20.7556 6.35932C20.9307 6.65998 21.0182 6.81032 21.0122 6.9337C21.0057 7.06711 20.9359 7.18919 20.8243 7.26209C20.7211 7.32954 20.5476 7.32954 20.2005 7.32954Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M8.87388 2.42334e-09H7.38772C7.04453 -4.59849e-07 6.87293 -4.40857e-07 6.77012 0.0667327C6.65899 0.138852 6.58887 0.259847 6.58133 0.392399C6.57435 0.515049 6.65922 0.66464 6.82897 0.963823L9.98883 6.53365C10.1651 6.84416 10.2531 6.99949 10.3644 7.05602C10.4847 7.11713 10.627 7.11584 10.7461 7.05251C10.8563 6.99389 10.9416 6.83704 11.112 6.5233L11.8689 5.13024C11.9884 4.91039 12.0482 4.80041 12.0735 4.68512C12.1011 4.55961 12.0993 4.42939 12.0684 4.30467C12.0399 4.19011 11.9772 4.08183 11.8518 3.86529L9.98569 0.642017C9.85759 0.420759 9.79357 0.310133 9.70638 0.227451C9.61148 0.13746 9.49674 0.0712167 9.37156 0.0341C9.2565 0 9.12891 2.42334e-09 8.87388 2.42334e-09Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      d="M14.0074 0H12.3254C11.9791 0 11.806 1.37768e-08 11.7029 0.0672967C11.5914 0.140021 11.5215 0.261927 11.5148 0.395122C11.5086 0.518382 11.5956 0.668547 11.7696 0.96888L12.6092 2.41818C12.7815 2.71556 12.8676 2.86425 12.9768 2.91989C13.0947 2.98005 13.2342 2.98013 13.3522 2.92017C13.4614 2.86467 13.5477 2.71611 13.7204 2.41898L14.5628 0.96968C14.7374 0.669161 14.8248 0.518904 14.8187 0.395552C14.8122 0.262255 14.7422 0.140202 14.6308 0.0673845C14.5276 4.82188e-07 14.3542 0 14.0074 0Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Ava;
