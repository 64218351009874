import * as React from "react";

const ArrowLine = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.6804 5.29294C23.8935 5.48814 24 5.74407 24 6C24 6.25593 23.8935 6.51186 23.6804 6.70713L18.2259 11.7071C17.7999 12.0976 17.1092 12.0976 16.6831 11.7071C16.2572 11.3166 16.2572 10.6834 16.6831 10.2929L20.2754 6.99999L1.09091 6.99999C0.488437 6.99999 2.86409e-07 6.55226 2.62268e-07 6C2.38128e-07 5.44774 0.488437 5.00001 1.09091 5.00001L20.2754 5.00001L16.6831 1.70709C16.2572 1.31656 16.2572 0.683429 16.6831 0.292898C17.1091 -0.0976335 17.7999 -0.0976336 18.2259 0.292898L23.6804 5.29294Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default ArrowLine;
