import * as React from "react";

const Income = ({ fill, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_17:9883)">
      <path
        d="M9.69439 13.8259C9.51982 13.4651 9.67402 13.0287 10.0348 12.8541C11.0386 12.3711 11.8881 11.6205 12.4933 10.6807C13.113 9.71767 13.4418 8.60043 13.4418 7.44827C13.4418 4.1431 10.7534 1.45474 7.44827 1.45474C4.1431 1.45474 1.45474 4.1431 1.45474 7.44827C1.45474 8.60042 1.78351 9.71767 2.40032 10.6836C3.00258 11.6234 3.85506 12.374 4.85883 12.857C5.21961 13.0316 5.37381 13.4651 5.19924 13.8288C5.02467 14.1895 4.59116 14.3437 4.22748 14.1692C2.97931 13.5698 1.92317 12.6388 1.17543 11.4692C0.407327 10.2705 -3.88146e-07 8.87973 -3.25574e-07 7.44827C-2.38585e-07 5.45819 0.773922 3.58739 2.18211 2.18211C3.58739 0.773922 5.45819 2.38585e-07 7.44827 3.25574e-07C9.43836 4.12564e-07 11.3092 0.773922 12.7144 2.18211C14.1226 3.5903 14.8965 5.45819 14.8965 7.44827C14.8965 8.87974 14.4892 10.2705 13.7182 11.4692C12.9676 12.6359 11.9114 13.5698 10.6662 14.1692C10.3025 14.3408 9.86896 14.1895 9.69439 13.8259Z"
        fill={fill}
      />
      <path
        d="M10.2793 7.83526C10.4219 7.97783 10.4917 8.16403 10.4917 8.35024C10.4917 8.53645 10.4219 8.72265 10.2793 8.86522L8.44924 10.6953C8.18156 10.963 7.82661 11.1084 7.45129 11.1084C7.07596 11.1084 6.7181 10.96 6.45333 10.6953L4.62327 8.86522C4.33814 8.58009 4.33814 8.12039 4.62327 7.83526C4.9084 7.55013 5.3681 7.55013 5.65323 7.83526L6.72682 8.90886L6.72682 4.77449C6.72682 4.37298 7.05269 4.04712 7.4542 4.04712C7.8557 4.04712 8.18157 4.37298 8.18157 4.77449L8.18157 8.91177L9.25516 7.83817C9.53447 7.55304 9.99417 7.55304 10.2793 7.83526Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_17:9883">
        <rect
          width="14.8965"
          height="14.8965"
          fill="white"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Income;
