import { useState } from "react";
import { Arrow } from "src/commons/Icons";
import Pagination from "src/components/ui/input/Pagination";
import PoolsTableRows from "src/components/ui/row/PoolsTableRows";
import { POOL_CURRENCIES } from "src/data/currencyData";
import useWindowSize from "src/utils/useWindowSize";

const PoolsTable = () => {
  const { width } = useWindowSize();

  return (
    <div className="h-full bg-custom-gray300 border-solid border-[1px] border-custom-gray400 rounded-[8px] px-[19px] dark:bg-custom-blue800 dark:border-custom-blue1300">
      <div className="w-full">
        <div className="overflow-y-auto">
          <div className="grid grid-cols-poolsTable py-[12px] justify-items-start items-center lgx:py-[6px]">
            <span className="font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              #
            </span>
            <span className="font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              Havuz
            </span>
            <span className="font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              Havuz
            </span>
            <p className="flex flex-col font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              Havuz Geliri
              <span className="text-sm leading-none lgx:text-[8px] lgx:leading-none">
                Son 24 Saat
              </span>
            </p>
            <p className="flex flex-col font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              Havuz Geliri
              <span className="text-sm leading-none lgx:text-[8px] lgx:leading-none">
                Son 1 Saat
              </span>
            </p>
            <span className="flex items-center font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              TVL
              <Arrow fill="#647F89" size="14" className="ml-[9px] rotate-90" />
            </span>
            <p className="flex flex-col font-semibold text-lg text-custom-gray800 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
              Volume
              <span className="text-sm leading-none lgx:text-[8px] lgx:leading-none">
                (24 H)
              </span>
            </p>
          </div>
          <div className="h-[410px] lgx:h-[120px]">
            {/* //TODO actiavete this for mobile and web  */}
          {/* ({ length: width > 1024 ? 8 : 4 }) */} 
            {POOL_CURRENCIES.map((item, index) => (
              <PoolsTableRows key={index} _key={index} item={item} />
            ))}
          </div>
        </div>
        <Pagination className="w-full" />
      </div>
    </div>
  );
};

export default PoolsTable;
