import { Ethereum, StarFilled, Star } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";
import { useState } from "react";

const ListPriceRow = props => {
  const {setCurrencyOne, _key, item} = props;
  const [isFav, setIsFav] = useState(false);
  const { width } = useWindowSize();
  return (
    <div
      className={`grid grid-cols-4 gap-[60px] py-[3px] px-[48px] justify-items-start items-center ${
        _key % 2 === 1
          ? "bg-custom-gray300 dark:bg-custom-blue800"
          : "bg-listPriceGradient rounded-b-[8px] dark:bg-listPriceGradientDark"
      }`}
    >
      <div className="flex items-center ml-[-32px]">
        <span className="cursor-pointer" onClick={() => setIsFav(!isFav)}>
          {isFav ? (
            <StarFilled
              fill="#DF6E4E"
              size={width > 1024 ? "14" : "10"}
              className="mr-[18px]"
            />
          ) : (
            <Star
              fill="#DF6E4E"
              size={width > 1024 ? "14" : "10"}
              className="mr-[18px]"
            />
          )}
        </span>
        <span className="rounded-full h-[min-content] bg-custom-blue100 p-[2.5px] flex items-center justify-center dark:bg-custom-blue500">
          <Ethereum
            size={width > 1024 ? "15" : "10"}
            className="pl-[1px] lgx:pr-[1px] lgx:pl-[unset]"
          />
        </span>
        <span onClick={()=>setCurrencyOne(item)} className="text-sm text-custom-blue400 text-opacity-[0.54] ml-[9px] dark:text-white cursor-pointer">
          {item}
        </span>
      </div>
      <span className="text-custom-blue400 text-opacity-[0.54] text-xs dark:text-white">
        0.0003254
      </span>
      <span className="text-custom-blue400 text-opacity-[0.54] text-xs dark:text-white">
        5.3774316
      </span>
      <span className="text-custom-red200 text-xs justify-self-center dark:text-custom-red100">
        -1.31%
      </span>
    </div>
  );
};

export default ListPriceRow;
