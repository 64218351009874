import * as React from "react";

const MenuOpen = ({ fill, fillOpacity, className }) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.227539 2.06083C0.227539 1.71926 0.361287 1.39168 0.599361 1.15016C0.837435 0.908636 1.16033 0.772949 1.49702 0.772949H16.7308C17.0675 0.772949 17.3904 0.908636 17.6284 1.15016C17.8665 1.39168 18.0003 1.71926 18.0003 2.06083C18.0003 2.40239 17.8665 2.72997 17.6284 2.9715C17.3904 3.21302 17.0675 3.34871 16.7308 3.34871H1.49702C1.16033 3.34871 0.837435 3.21302 0.599361 2.9715C0.361287 2.72997 0.227539 2.40239 0.227539 2.06083Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.227539 8.50022C0.227539 8.15866 0.361287 7.83108 0.599361 7.58955C0.837435 7.34803 1.16033 7.21234 1.49702 7.21234H16.7308C17.0675 7.21234 17.3904 7.34803 17.6284 7.58955C17.8665 7.83108 18.0003 8.15866 18.0003 8.50022C18.0003 8.84179 17.8665 9.16936 17.6284 9.41089C17.3904 9.65241 17.0675 9.7881 16.7308 9.7881H1.49702C1.16033 9.7881 0.837435 9.65241 0.599361 9.41089C0.361287 9.16936 0.227539 8.84179 0.227539 8.50022Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.227539 14.9396C0.227539 14.598 0.361287 14.2705 0.599361 14.0289C0.837435 13.7874 1.16033 13.6517 1.49702 13.6517H16.7308C17.0675 13.6517 17.3904 13.7874 17.6284 14.0289C17.8665 14.2705 18.0003 14.598 18.0003 14.9396C18.0003 15.2812 17.8665 15.6088 17.6284 15.8503C17.3904 16.0918 17.0675 16.2275 16.7308 16.2275H1.49702C1.16033 16.2275 0.837435 16.0918 0.599361 15.8503C0.361287 15.6088 0.227539 15.2812 0.227539 14.9396Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default MenuOpen;
