import { ArrowLine } from "src/commons/Icons";

const PoolOverview = () => {
  return (
    <div className="w-full h-full">
      <h1 className="mt-[5px] mb-[11px] text-xl text-custom-blue400 font-semibold lgx:text-sm dark:text-white">
        Havuzlara Genel Bakış
      </h1>
      <div className="relative w-full h-minus44 flex flex-col items-center justify-center bg-poolOverview bg-center border-solid border-[1px] border-custom-blue100 border-opacity-[0.5] rounded-[8px] shadow-homeCard dark:bg-poolOverviewDark">
        <h1 className="mb-[3px] text-xl text-custom-blue100 font-semibold lgx:text-sm dark:text-white">
          DeXimec V3 Burada!
        </h1>
        <p className="text-base text-custom-blue100 px-[20px] lgx:text-xs dark:text-white">
          Kontrol Et V3 Lp İzlenecek Yol Ve Geçiş Kılavuzları
        </p>
        <ArrowLine
          size="20"
          className="absolute bottom-[16px] right-[25px] fill-[#284862] dark:fill-[#FFFFFF]"
        />
      </div>
    </div>
  );
};

export default PoolOverview;
