import { useState, useEffect } from "react";

function useThemeMode() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    function handleResize() {
      localStorage.setItem("theme", theme);
      if (
        theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        document.documentElement.classList.add("dark");
        document.documentElement.classList.remove("light");
      } else {
        document.documentElement.classList.add("light");
        document.documentElement.classList.remove("dark");
      }
    }
    handleResize();
  }, [theme]);

  return { theme, setTheme };
}

export default useThemeMode;
