import * as React from "react";

const Ellipse = ({ fill, fillOpacity, className, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 2}
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default Ellipse;
