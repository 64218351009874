export const CURRENCIES = [
    'BTC',
    'ETH',
    'BNB',
    'ADA',
    'AVAX',
    'DOGE',
    'EUR',
    'BTT',
    'DENT',
    'CHZ',
    'TRX'
  ]

  export const POOL_CURRENCIES = [
    {first: 'ETH', second: 'USDT' },
    {first: 'BTC', second: 'USDT' },
    {first: 'BNB', second: 'USDT' },
    {first: 'ADA', second: 'USDT' },
    {first: 'AVAX', second: 'USDT' },
    {first: 'DOGE', second: 'USDT' },
    {first: 'BTT', second: 'USDT' },
    {first: 'DENT', second: 'USDT' },
    {first: 'CHZ', second: 'USDT' },
    {first: 'TRX', second: 'USDT' },
  ]