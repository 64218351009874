import * as React from "react";

const HexagonFilled = ({ fill, fillOpacity, className, size }) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.7775 7.17063L14.108 0.828999C13.8108 0.315728 13.2627 0 12.6693 0H5.33057C4.73718 0 4.18918 0.315728 3.8918 0.828999L0.222507 7.17063C-0.0741689 7.68386 -0.0741689 8.31547 0.222507 8.82878L3.8918 15.171C4.18918 15.6842 4.73718 16 5.33057 16H12.6692C13.2627 16 13.8108 15.6842 14.108 15.171L17.7775 8.82878C18.0742 8.31547 18.0742 7.68386 17.7775 7.17063Z"
      fill="#DF6E4E"
    />
  </svg>
);

export default HexagonFilled;
