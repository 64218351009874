import { Ellipse, Income } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";

const TransactionSummaryRow = ({ _key }) => {
  const { width } = useWindowSize();
  return (
    <div className="grid grid-flow-col auto-cols-[minmax(120px,160px)] py-[4px] px-[31px] justify-items-start items-center border-b-[1px] border-solid border-custom-blue400 border-opacity-[0.07] dark:border-white/5">
      <div className="flex items-center ml-[-21px]">
        <span>
          <Income
            fill="#284862"
            size={width > 1024 ? "15" : "10"}
            className="mr-[7px]"
          />
        </span>
        <span className="text-xs text-custom-blue400 truncate w-full dark:text-white">
          Gelen
        </span>
      </div>
      <span className="text-custom-blue400 truncate w-full text-xs dark:text-white">
        May 29, 2020
      </span>
      <span className="text-custom-blue400 truncate w-full text-xs dark:text-white">
        1051C8dgE37121051C8dgE37121051C8dgE3712
      </span>
      <span className="text-custom-blue400 truncate w-full text-xs dark:text-white">
        teklif oluştur
      </span>
      <span className="text-custom-blue400 truncate w-full text-xs dark:text-white">
        TDL
      </span>
      <span className="text-custom-blue400 truncate w-full text-xs flex items-center dark:text-white">
        <Ellipse fill="#284862" size="4" className="mr-[4px]" />
        Onay
      </span>
      <span className="text-custom-blue400 truncate w-full text-xs dark:text-white">
        215690
      </span>
    </div>
  );
};

export default TransactionSummaryRow;
