import { useState, useRef } from "react";
import { Chart as ChartIcon } from "src/commons/Icons";
import useWindowSize from "src/utils/useWindowSize";
import useOnClickOutside from "src/utils/useOnClickOutside";
const ChartDropdown = ({ className, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref} className={`${className} relative cursor-pointer`}>
      <div
        className={`w-max flex items-center px-[10px] py-[7px] bg-custom-gray900 rounded-[4px] lgx:px-[7px] lgx:py-[5px] dark:bg-custom-blue1200`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-[8px]">
          <ChartIcon
            size={width > 1024 ? "20" : "14"}
            className="fill-[rgba(0,0,0,0.54)] dark:fill-[rgba(255,255,255,0.7)]"
          />
        </span>
        <span className="text-black text-opacity-[0.54] lgx:text-[10px] dark:text-white/70">
          {value ? value : "Grafik Seç"}
        </span>
      </div>
      {isOpen ? (
        <div className="z-[12] absolute p-[16px] bg-custom-gray700 left-0 top-[38px] shadow-swapDropdown rounded-[8px] dark:bg-custom-blue1200">
          <div className="grid grid-flow-row gap-[14px] overflow-x-auto overflow-y-hidden scrollhide">
            <div
              onClick={() => {
                onChange("candlestick");
                setIsOpen(false);
              }}
              className="flex bg-white items-center justify-center rounded-[6px] px-[14px] py-[8px] dark:bg-custom-blue900"
            >
              <span className="text-black text-opacity-[0.38] mr-[11px] dark:text-white/70">
                Candle
              </span>
            </div>
            <div
              onClick={() => {
                onChange("area");
                setIsOpen(false);
              }}
              className="flex bg-white items-center justify-center rounded-[6px] px-[14px] py-[8px] dark:bg-custom-blue900"
            >
              <span className="text-black text-opacity-[0.38] mr-[11px] dark:text-white/70">
                Area
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChartDropdown;
