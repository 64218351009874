import { Ethereum } from "src/commons/Icons";

const Liquidity = props => {
  const {currencyOne, currencyTwo} = props;

  return (
    <div className="w-full h-full flex flex-col items-center">
      <div className="w-full flex mb-[8px]">
        <button className="text-base w-full border-none rounded-[6px] bg-custom-orange text-custom-gray600 flex items-center justify-center py-[8px] mr-[8px] lgx:text-sm">
          Likiditeyi Artırın
        </button>
        <button className="text-base w-full border-none rounded-[6px] bg-custom-gray1100 text-black/50 flex items-center justify-center py-[8px] dark:bg-custom-blue900 dark:text-white lgx:text-sm">
          Likiditeyi Kaldır
        </button>
      </div>
      <div className="w-full h-full flex flex-col px-[32px] py-[17px] bg-custom-gray300 border-solid border-[1px] rounded-lg border-custom-gray400 shadow-homeCard dark:bg-custom-blue800 dark:border-custom-blue1300">
        <h1 className="text-custom-gray1200 text-base font-semibold mb-[6px] dark:text-white">
          Likidite
        </h1>
        <span className="text-custom-blue1400 text-2xl font-bold mb-[13px] dark:text-white lgx:text-base">
          $682.41
        </span>
        <div className="px-[24px] py-[8px] flex flex-col bg-custom-gray1100 border-solid border-[1px] rounded-lg border-custom-gray400 dark:bg-custom-blue900 dark:border-custom-blue700">
          <div className="flex items-center my-[12px]">
            <p className="text-custom-blue1400 text-lg mr-auto flex items-center dark:text-white/60 lgx:text-base">
              <span className="flex items-center justify-center rounded-full p-[2.1px] bg-custom-blue300 mr-[10px]">
                <Ethereum size="16" />
              </span>
              {currencyOne}
            </p>
            <span className="text-custom-blue1400 text-lg mr-[40px] dark:text-white/60 lgx:text-base">
              0
            </span>
            <span className="text-custom-blue1400 text-lg dark:text-white/60 lgx:text-base">
              0%
            </span>
          </div>
          <div className="flex items-center my-[12px]">
            <p className="text-custom-blue1400 text-lg mr-auto flex items-center dark:text-white/60 lgx:text-base">
              <span className="flex items-center justify-center rounded-full p-[2.1px] bg-custom-blue300 mr-[10px]">
                <Ethereum size="16" />
              </span>
              {currencyTwo}
            </p>
            <span className="text-custom-blue1400 text-lg mr-[40px] dark:text-white/60 lgx:text-base">
              682
            </span>
            <span className="text-custom-blue1400 text-lg dark:text-white/60 lgx:text-base">
              100%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Liquidity;
