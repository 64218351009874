import { useState } from "react";
import { useLocation } from "react-router";
import {
  Wallet,
  PriceRange,
  CurrentPrice,
  Liquidity,
  Request,
  Trade,
  Download,
} from "src/components/view/Cards";

const LiquidityPoolSigned2 = () => {
  const location = useLocation();
  const {currencyOne, currencyTwo} = location.state;
  return (
    <div className="mr-[30px] grid grid-cols-liquidityPoolLoggedWeb justify-center gap-4 grid-rows-liquidityPoolLoggedWeb2 xlx:grid-rows-liquidityPoolLoggedTablet2 xlx:mx-[20px] xlx:grid-cols-liquidityPoolLoggedTablet2 lgx:grid-cols-1 lgx:grid-rows-liquidityPoolLoggedMobile2 lgx:mx-[13px]">
      <div className="order-1 bg-black xlx:order-5 xlx:col-span-2 lgx:col-span-1">
        <Trade />
      </div>
      <div className="order-2 xlx:order-1">
        <Wallet />
      </div>
      <div className="order-3 xlx:order-6 xlx:col-span-2 lgx:col-span-1">
        <PriceRange currencyOne={currencyOne} currencyTwo={currencyTwo} />
      </div>
      <div className="order-4 xlx:order-3 ">
        <Liquidity currencyOne={currencyOne} currencyTwo={currencyTwo} />
      </div>
      <div className="order-5 xlx:order-7 xlx:col-span-2 lgx:col-span-1">
        <CurrentPrice currencyOne={currencyOne} currencyTwo={currencyTwo} />
      </div>
      <div className="order-6 xlx:order-4">
        <Request currencyOne={currencyOne} currencyTwo={currencyTwo} />
      </div>
      <div className="hidden xlx:block xlx:order-2">
        <Download />
      </div>
    </div>
  );
};

export default LiquidityPoolSigned2;
