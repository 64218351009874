import { Arrow } from "src/commons/Icons";
import Pagination from "src/components/ui/input/Pagination";
import RecentPoolTransactionsRow from "src/components/ui/row/RecentPoolTransactionsRow";
import { CURRENCIES, POOL_CURRENCIES } from "src/data/currencyData";
import useWindowSize from "src/utils/useWindowSize";

const RecentPoolTransactions = props => {
  const { width } = useWindowSize();

  return (
    <div>
      <h1 className="text-custom-blue400 text-opacity-50 text-xl font-semibold mb-[7px] lgx:text-base">
        Son Havuz İşlemleri
      </h1>
      <div className="h-full bg-custom-gray300 border-solid border-[1px] border-custom-gray400 rounded-[8px] px-[19px] dark:bg-custom-blue800 dark:border-custom-blue1300">
        <div className="w-full">
          <div className="overflow-y-auto">
            <div className="grid grid-flow-col auto-cols-[minmax(120px,180px)] py-[9px] justify-items-start items-center lgx:py-[6px]">
              <span className="text-sm text-custom-blue400 text-opacity-50 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
                <b>Herşey</b> Takaslar Ekler Kaldırır
              </span>
              <span className="text-sm text-custom-blue400 text-opacity-50 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
                Toplam Değer
              </span>
              <span className="text-sm text-custom-blue400 text-opacity-50 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
                Token Miktarı
              </span>
              <span className="text-sm text-custom-blue400 text-opacity-50 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
                Token Miktarı
              </span>
              <span className="text-sm text-custom-blue400 text-opacity-50 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
                Hesap
              </span>
              <span className="flex items-center justify-end text-sm text-custom-blue400 text-opacity-50 truncate w-full lgx:text-[10px] lgx:leading-none dark:text-white">
                Zaman
                <Arrow
                  fill="#647F89"
                  size="10"
                  className="ml-[8px] rotate-90"
                />
              </span>
            </div>
            <div className="h-[160px] lgx:h-[65px]">
              {/* ({ length: width > 1024 ? 6 : 4 }) */}
              {POOL_CURRENCIES.map(
                (item, index) => (
                  <RecentPoolTransactionsRow key={index} _key={index} item={item} />
                )
              )}
            </div>
          </div>
          <Pagination className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default RecentPoolTransactions;
