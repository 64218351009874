const INVALID_CALL_STATE = { valid: false, result: undefined, loading: false, syncing: false, error: false }
const LOADING_CALL_STATE = { valid: true, result: undefined, loading: true, syncing: true, error: false }

export function toCallState(
  callResult,
  contractInterface,
  fragment,
  latestBlockNumber
) {
  if (!callResult) return INVALID_CALL_STATE
  const { success, returnData } = callResult
//   if (!valid) return INVALID_CALL_STATE
//   if (valid && !blockNumber) return LOADING_CALL_STATE
//   if (!contractInterface || !fragment || !latestBlockNumber) return LOADING_CALL_STATE
//   const success = data && data.length > 2
//   const syncing = (blockNumber ?? 0) < latestBlockNumber
  let result = undefined;
  if (success && returnData) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, returnData)
    } catch (error) {
      console.debug('Result data parsing failed', fragment, returnData)
      return {
        valid: success,
        loading: false,
        error: !success,
        // syncing,
        result,
      }
    }
  }
  return {
    valid: success,
    loading: false,
    // syncing,
    result,
    error: !success,
  }
}